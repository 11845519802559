import { Box, Heading } from '@chakra-ui/react';
import { InfoBox } from './ui-components/infoBox.component';
import { InfoBoxGrid } from './ui-components/InfoBoxGrid.component';
import { useLocalization } from '../hooks/useLocalization.hook';
import { formatNumber } from '../utils/formatNumber.util';
import { DailyAnalytics } from '../api/models.api';
import React from 'react';

const RestaurantAnalyticsData = React.memo(({ restaurantDailyData }: { restaurantDailyData: DailyAnalytics }) => {
    const { t } = useLocalization();
    return (
        <Box display={'flex'} flexDir={'column'} gap={4}>
            <InfoBoxGrid width={'100%'}>
                <InfoBox direction="column" normalText={t("analyticsKeys.dailyIncome")} boldText={formatNumber({ number: restaurantDailyData?.dailyIncome, allowZero: true, t })} />
                <InfoBox direction="column" normalText={t("analyticsKeys.dailyCreditIncome")} boldText={formatNumber({ number: restaurantDailyData?.creditIncome, allowZero: true, t })} />
                <InfoBox direction="column" normalText={t("analyticsKeys.dailyCashIncome")} boldText={formatNumber({ number: restaurantDailyData?.cashIncome, allowZero: true, t })} />
                <InfoBox direction="column" normalText={t("analyticsKeys.guestAmount")} boldText={restaurantDailyData?.guestAmount?.toString() ?? t('errors.noData')} />
            </InfoBoxGrid>
        </Box >
    );
})
export default RestaurantAnalyticsData