import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component'
import { InfoBox } from '../ui-components/infoBox.component'
import { useLocalization } from '../../hooks/useLocalization.hook'
import { formatNumber } from '../../utils/formatNumber.util'

type Props = {
    yesterdayData: number
    startOfMonthData: number
}

function Refunds({ startOfMonthData, yesterdayData }: Props) {
    const { t } = useLocalization();
    return (
        <Box>
            <Heading mb={4} size={"md"}>{t('expensesKeys.expenses_refunds')}</Heading>
            <InfoBoxGrid w={'100%'} display='grid'>
                <InfoBox direction="column" normalText={t('dateAndTimeKeys.yesterday')} boldText={formatNumber({ number: yesterdayData, allowZero: true, t })} />
                <InfoBox direction="column" normalText={t('dateAndTimeKeys.from_month_start')} boldText={formatNumber({ number: startOfMonthData, allowZero: true, t })} />
            </InfoBoxGrid>
        </Box>
    )
}

export default Refunds