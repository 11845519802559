import React from 'react';
import { Box, BoxProps, ResponsiveValue, useMediaQuery } from '@chakra-ui/react';
import breakpoints from '../../styles/breakpoints';

export interface infoBoxGrid extends BoxProps {
    children: React.ReactNode;
    icon?: string;
    rows?: number;
    display?: 'grid' | 'flex'
}

export const InfoBoxGrid = (props: infoBoxGrid) => {
    const { icon, children, display, ...restProps } = props;
    const [isMobile] = useMediaQuery(breakpoints.isMobile);
    return (
        <Box
            display={display ?? isMobile ? "grid" : 'flex'}
            w={'fit-content'}
            minW={'375px'}
            maxW={'100vw'}
            background={'primary'}
            borderRadius={'md'}
            p={['10px', '20px']}
            gap={['10px', '20px']}
            {...restProps}
        >
            {children}
        </Box>
    );
};
