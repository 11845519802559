import React, { useMemo } from 'react'
import { Box, Heading, Link } from '@chakra-ui/react'
import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component'
import { InfoBox } from '../ui-components/infoBox.component'
import { useLocalization } from '../../hooks/useLocalization.hook'
import { formatNumber } from '../../utils/formatNumber.util'
import { Link as ReactLink } from 'react-router-dom';


type Props = {
    yesterdayData: number
    startOfMonthData: number
    showLink?: boolean
}

function DishCosts({ startOfMonthData, yesterdayData, showLink }: Props) {
    const { t } = useLocalization();
    return (
        <Box>
            <Box w={'100%'} display={'flex'} flexDir={'row'} justifyContent={'space-between'}>
                <Heading size={"md"} mb={4}>{t('expensesKeys.expenses_dishes')}</Heading>
                {showLink && <Link variant="secondary-title" as={ReactLink} to={location.pathname + "/expenses"}>{t('routingKeys.toAllExpenses') + " >"}</Link>}
            </Box>
            <InfoBoxGrid w={'100%'}>
                <InfoBox direction="column" normalText={t('dateAndTimeKeys.yesterday')} boldText={formatNumber({ number: yesterdayData, t })} />
                <InfoBox direction="column" normalText={t('dateAndTimeKeys.from_month_start')} boldText={formatNumber({ number: startOfMonthData, t })} />
            </InfoBoxGrid>
        </Box>
    )
}

export default DishCosts