import React from "react";
import { Grid, Box, useMediaQuery, Spinner, Stack, Heading, GridItem, Flex } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { DailyAnalytics } from "../api/models.api";
import AveragePerGuest from "../components/income-page-components/averagePerGuest.component";
import DeliveryIncomes from "../components/income-page-components/deliveryIncomes.component";
import Gauge from "../components/income-page-components/incomeGague.component";
import IncomePerDayGraph from "../components/income-page-components/incomePerDayGraph.component";
import RestaurantAnalyticsData from "../components/restaurantAnalyticsData.component";
import { useGlobalDateContext } from "../contexts/DateContext";
import useAuth from "../hooks/useAuth.hook";
import { useLocalization } from "../hooks/useLocalization.hook";
import useRestaurant from "../hooks/useRestaurant.hook";
import useRestaurantDailyData from "../hooks/useRestaurantDailyData.hook";
import { useRestaurantDateRange } from "../hooks/useRestaurantDateRange.hook";
import breakpoints from "../styles/breakpoints";
import { calcStartOfPickedDateMonth, getNextDay } from "../utils/dates.util";

const IncomePage = () => {
    const { me } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useLocalization();
    const [isMobile] = useMediaQuery(breakpoints.isMobile);
    const {selectedDate} = useGlobalDateContext();

    if (!me) { navigate("/login"); }
    if (!id) { navigate("/restaurants"); }

    const restaurantId = parseInt(id!);
    const {
        restaurantDailyReportsData: pickedDateData,
        restaurantDailyReportsDataLoading: isPickedDateDateLoading,
    } = useRestaurantDateRange(restaurantId, {
        startDate: selectedDate,
        endDate: getNextDay(selectedDate),
    });

    const {
        restaurantDailyReportsData: startOfMonthData,
        restaurantDailyReportsDataLoading: isStartOfMonthDataLoading,
    } = useRestaurantDateRange(restaurantId, {
        startDate: calcStartOfPickedDateMonth(selectedDate),
        endDate: getNextDay(selectedDate),
    });
    const { restaurant, restaurantLoading } = useRestaurant(restaurantId);
    const {restaurantDailyData} = useRestaurantDailyData(restaurantId);


    if (isPickedDateDateLoading || isStartOfMonthDataLoading) { return <Spinner />; }
    if (!startOfMonthData) { return <h1>Error</h1>; }
    if (restaurantLoading || !restaurant) { return <Spinner />; }
    if (isMobile) {
        return (
            <Stack spacing={"2vh"} justifyContent={"center"} w={"95vw"}>
                <Heading>{t("pageName.incomes")}</Heading>
                <RestaurantAnalyticsData
                    restaurantDailyData={
                        pickedDateData ?? ({} as DailyAnalytics)
                    }
                />

                <Gauge
                    actual={startOfMonthData.dailyIncome}
                    total={restaurant?.income_goal ?? 0}
                />
                <DeliveryIncomes
                    yesterdayCount={restaurantDailyData?.analytics?.deliveryCount}
                    startOfMonthData={startOfMonthData?.deliveryIncome}
                    yesterdayData={restaurantDailyData?.analytics?.deliveryIncome ?? 0}
                />
                <IncomePerDayGraph />
                <AveragePerGuest
                    averagePerGuest={
                        startOfMonthData.dailyIncome / startOfMonthData.guestAmount
                    }
                />
            </Stack>
        );
    }

    return (
        <>
            <Heading mb={"20px"}>{t("pageName.incomes")}</Heading>
            <Grid templateColumns={"repeat(8,1fr)"} columnGap={"20px"} rowGap={"3vh"}>
                <GridItem colSpan={8}>
                    <RestaurantAnalyticsData
                        restaurantDailyData={
                            pickedDateData ?? ({} as DailyAnalytics)
                        }
                    />
                </GridItem>
                <GridItem colSpan={8}>
                    <Flex alignItems={"center"} w="full" gap={"20px"}>
                        <Gauge
                            actual={startOfMonthData.dailyIncome}
                            total={restaurant?.income_goal ?? 0}
                        />
                        <Box flex="1" style={{ minWidth: 0 }}>
                            <IncomePerDayGraph />
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem colSpan={8}>
                    <DeliveryIncomes
                        yesterdayData={restaurantDailyData?.analytics?.deliveryIncome ?? 0}
                        startOfMonthData={startOfMonthData?.deliveryIncome}
                        yesterdayCount={restaurantDailyData?.analytics?.deliveryCount}
                        startOfTheMonthCount={startOfMonthData?.deliveryCount}
                    />
                </GridItem>
                <GridItem colSpan={3}>
                    <AveragePerGuest
                        averagePerGuest={
                            startOfMonthData.dailyIncome / startOfMonthData.guestAmount
                        }
                    />
                </GridItem>
            </Grid>
        </>
    );
};

export default IncomePage;
