import { useToasts } from '../hooks/useToasts.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { SWRConfig } from 'swr';

function CustomSWRConfig(props: { children: JSX.Element }) {
    const { errorToast } = useToasts();
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <SWRConfig
            value={{
                onErrorRetry(err) {
                    //Do not send another request if failed on those errors
                    if (err.status === 401 || 403) return;
                },
                onError: (error, _key) => {
                    if (!error.response || !error.response.status) return errorToast('errors.internal_error')
                    const status = error.response.status;
                    if (status === 403) {
                        return errorToast('errors.forbidden')
                    }
                    if (status === 401 && location.pathname !== '/') {
                        return navigate('/login')
                    }
                    if (status === 500) {
                        return errorToast('errors.internal_error')
                    }
                }

            }}
        >
            {props.children}
        </SWRConfig>
    )
}

export default CustomSWRConfig