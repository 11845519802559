import { useState, useCallback, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import { useToasts } from "./useToasts.hook";
import { TotalTip } from "../api/models.api";
import { RestaurantsDailyAPI } from "../api/restaurantsDaily.api";
import { useGlobalDateContext } from "../contexts/DateContext";

export default function useRestaurantDailyData(
    restaurantId: number
) {
    const { errorToast, successToast, loadingToast, toastsManager } = useToasts();
    const {selectedDate} = useGlobalDateContext();

    const {
        data: restaurantDailyData,
        isLoading,
        error,
        mutate,
    } = useSWR(
        [
            `${restaurantId}`,
            RestaurantsDailyAPI.employeeShiftKey,
            selectedDate,
        ],
        ([ id, _key, selectedDate ]) => RestaurantsDailyAPI.getByDate(
            +id!,
            dayjs(selectedDate).toDate()
        ),
        {
            onErrorRetry: (fetchError) => {
                //Dont retry if no shift on that day
                if (fetchError.status === 404) { return; }
            },
            revalidateOnMount: true,
        }
    );
    const formActions = useForm<TotalTip<number>>();
    useEffect(() => {
        formActions.setValue(
            "creditTip",
            restaurantDailyData?.data.analytics?.tipTotal ?? 0
        );
        formActions.setValue(
            "completions",
            restaurantDailyData?.data.completions ?? 0
        );
        formActions.setValue("cashTip", restaurantDailyData?.data.cashTip ?? 0);
    }, [restaurantDailyData]);

    const editOrCreateDescription = async (text?: string) => {
        if (!text) { return; }
        try {
            await RestaurantsDailyAPI.edit(restaurantDailyData?.data.id!, {
                description: text,
            });
            await mutate();
        } catch (e: unknown) {
            errorToast("errors.internal_error");
        }
    };
    const calculateTip = useMemo(() => {
        return async () => {
            if (isLoading || !restaurantDailyData?.data.analytics) { return; }
            const { cashTip, completions } = formActions.getValues();
            try {
                await RestaurantsDailyAPI.calculateTotalTip({
                    id: restaurantDailyData?.data.id!,
                    cashTip,
                    completions,
                });
                await mutate();
                successToast("successFeedbacks.data_success");
            } catch (e: unknown) {
                errorToast("errors.internal_error");
            }
        };
    }, [ restaurantDailyData?.data.analytics, formActions, selectedDate ]);

    const loadRestaurantData = useCallback(
        async (startDate?: Date, endDate?: Date) => {
            const start = startDate || dayjs().add(-30, "days").toDate();
            const end = endDate || dayjs().toDate();
            const loading = loadingToast("loading_data", null);
            try {
                await RestaurantsDailyAPI.loadRestaurantData({
                    restaurantId,
                    start,
                    end,
                });
                toastsManager.close(loading);
                successToast("successFeedbacks.data_success");
                await mutate();
            } catch (e) {
                toastsManager.close(loading);
                errorToast("errors.internal_error");
            }
        },
        [ errorToast, successToast, RestaurantsDailyAPI.loadRestaurantData ]
    );

    return {
        restaurantDailyData: restaurantDailyData?.data,
        isRestaurantDailyDataLoading: isLoading,
        editOrCreateDescription,
        calculateTip,
        formActions,
        selectedDate,
        revalidateDailyData: mutate,
        loadRestaurantData,
    };
}
