import { Box, Button, Flex, Heading, Input, Spinner, Stack, Text } from '@chakra-ui/react'
import { useLocalization } from '../../hooks/useLocalization.hook';
import { useCategories } from '../../hooks/useCategories.hook';


const CategoriesList = ({ restaurantId }: { restaurantId: number }) => {
    const { t, direction } = useLocalization();
    const { categories, isLoading, formActions } = useCategories(restaurantId);

    if (isLoading) return <Spinner />
    if (!categories) return <></>

    return (
        <Box p={[0, 10]} display={'flex'} flexDir={'column'} w={['95vw', '85vw', '80vw', '32vw']} as='form' onSubmit={formActions.handleSubmit(() => console.log(formActions.formState.touchedFields))}>
            <Heading mb={4}>{t('dishesKeys.categories')}</Heading>
            <Stack h={['45vh', '50vh', '55vh', '60vh']} overflowY={'scroll'} dir={direction}>
                {
                    categories.map((categoriesItem, index) =>
                        <Flex key={index} w='full' justifyContent={'space-between'}>
                            <Text>{categoriesItem.join(", ")}</Text>
                            <Input w={['30vw', '20vw', '10vw']} type='number' {...formActions.register(`${index}.price`, { min: 0 })} />
                        </Flex>)
                }
            </Stack>
            <Stack w='full' alignItems={'end'}><Button w={'fit-content'} my={10} type='submit'>{t('save')}</Button></Stack>
        </Box>
    )
}

export default CategoriesList