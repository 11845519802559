import React from "react";
import { Box, Button, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import useAuth, { AuthState } from "../hooks/useAuth.hook";
import { useLocalization } from "../hooks/useLocalization.hook";
import { usePreventScroll } from "../hooks/usePreventScroll.hook";
import breakpoints from "../styles/breakpoints";

function WelcomePage() {
    const [isMobile] = useMediaQuery(breakpoints.isMobile);
    const navigate = useNavigate();
    const { t, direction } = useLocalization();
    const anchor = direction === "rtl" ? { pr: 3 } : { pl: 3 };
    const _ = usePreventScroll();
    const {authStates, navUser} = useAuth();

    if (!authStates.includes(AuthState.GUEST)) {
        navUser();
    }

    if (!isMobile) {
        return (
            <Box overflow={"hidden"} display={"flex"} h='80vh' w={"100%"} maxWidth={"100%"} alignItems={"center"}>
                <Box w='75%' display={"flex"} flexDirection={"column"}>
                    <Heading size='4xl'>{t("welcomePage.header")}</Heading>
                    <Heading size='4xl'>{t("welcomePage.header_two")}</Heading>
                    <Box w='70%'>
                        <Text fontSize={[ "2xl", "xl", "md" ]} mt={2}>{t("welcomePage.description")}</Text>
                    </Box>
                    <Button mt={20} mr={10} w={"20%"} onClick={() => navigate("/login")}>{t("getStarted")}</Button>
                </Box>
                <Box w='25%'>
                    <ReactSVG src='svg/alfred_welcome.svg' style={{ transform: "scale(1.3)" }} />
                </Box>

            </Box >
        );
    }

    return (
        <Box display={"flex"} h={"110vh"} flexDir={"column"} justifyContent={"center"}>
            <Box h={"20vh"}>
                <Box position={"relative"}>
                    <ReactSVG src='svg/alfred_welcome.svg' style={{ left: 0, bottom: -200, position: "absolute", zIndex: 2 }} />
                    <ReactSVG src='svg/background_vector.svg' style={{ position: "absolute", left: 0, bottom: -300, zIndex: 1 }} />
                </Box>
            </Box>
            <Box {...anchor} mt={20} h={[ "50vh", "60vh" ]} zIndex={2} w='90%'>
                <Heading size={[ "2xl", "3xl" ]}>{t("welcomePage.header")}</Heading>
                <Heading size={[ "2xl", "3xl" ]}>{t("welcomePage.header_two")}</Heading>
                <Text fontSize={[ "md", "xl", "2xl" ]} mt={4}>{t("welcomePage.description")}</Text>
            </Box>
        </Box>
    );
}

export default WelcomePage;