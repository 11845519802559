import { Avatar, Box, Button, FormControl, Heading, Spinner, Text, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteTag } from '@choc-ui/chakra-autocomplete';
import { useLocalization } from '../../hooks/useLocalization.hook';
import useEmployees from '../../hooks/useEmployees.hook';
import useShifts from '../../hooks/useShifts.hook';
import { findUniqueElements } from '../../utils/findUniqueElementsById';

type Props = {
    restaurantId: number
    restaurantDailyId: number
}

const AssignEmployeesToShift = React.memo(({ restaurantId, restaurantDailyId }: Props) => {
    const { t, direction } = useLocalization();
    const { employees } = useEmployees(restaurantId);
    const { formActions, saveShifts, employeeShiftData, setEmployeeIds } = useShifts(restaurantDailyId);
    const availableEmployees = useMemo(() => findUniqueElements(employees ?? [], employeeShiftData?.data ?? []), [employees, employeeShiftData?.data]);
    const tagAnchor = direction === 'rtl' ? { ml: 1 } : { mr: 1 };


    if (!employees || !employeeShiftData) return <Spinner />

    return (
        <Box w="full">
            <Heading size='md' my={5}>{t('shiftReportKeys.assignEmployees')}</Heading>
            <FormControl as="form" w="full" onSubmit={formActions.handleSubmit(saveShifts)}>
                <Flex w='full'>
                    <Box w={['60%', '40%', '20%']}>
                        <AutoComplete openOnFocus multiple onChange={(values: Array<number>) => setEmployeeIds(values)}>
                            <AutoCompleteInput placeholder={t('shiftReportKeys.typeEmployeeName')} fontWeight={"bold"} border={"none"} borderRadius={'10px'}>
                                {({ tags }) =>
                                    tags.map((tag, tid) => (
                                        <AutoCompleteTag
                                            key={tid}
                                            label={tag.label}
                                            onRemove={tag.onRemove}
                                        />
                                    ))
                                }
                            </AutoCompleteInput>
                            <AutoCompleteList borderRadius={'10px'}>
                                {availableEmployees?.map((emp, indx) => (
                                    <AutoCompleteItem
                                        key={`option-${indx}`}
                                        value={emp}
                                        label={emp.name}
                                        getValue={(val) => String(val.id)}
                                        _selected={{ bg: "whiteAlpha.50" }}
                                        _focus={{ bg: "whiteAlpha.100" }}
                                    >
                                        <Flex w='full'>
                                            <Avatar size='xs' name={emp.name} {...tagAnchor} />
                                            <Text {...tagAnchor} >{emp.name}</Text>
                                        </Flex>
                                    </AutoCompleteItem>
                                ))}
                            </AutoCompleteList>
                        </AutoComplete>
                    </Box>
                    <Button isLoading={formActions.formState.isSubmitting} type="submit" mx={5}>{t('add')}</Button>
                </Flex>
            </FormControl >
        </Box >
    )
})

export default AssignEmployeesToShift