import useSWR from "swr";
import useRestaurantValidation from "./useRestaurantValidation.hook"
import { EmployeeShiftAPI } from "../api/employeeShift.api";
import dayjs from "dayjs";

export function useEmployeesCosts( restaurantId: number,start:Date,end:Date) {
    const {id} = useRestaurantValidation(restaurantId);
    const endDateWithoutTime = dayjs(dayjs(end).format('YYYY-MM-DD')).toDate();
    const { data, isLoading } = useSWR(id && start && endDateWithoutTime ? [id, 'employeesCostsDay',start] : null, ([id, _key,start]) => EmployeeShiftAPI.getEmployeesCosts(+id, {start,end}))
    return {
        data,
        isLoading
    }
}