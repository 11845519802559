import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR, { useSWRConfig } from 'swr';
import { Employee } from '../api/models.api';
import { EmployeesAPI } from '../api/employees.api';
import { RequestResponseType } from '../utils/api.util';
import { useToasts } from './useToasts.hook';
import { EmployeeResponse } from '../api/response.types';
import { AxiosError } from 'axios';

export default function useEmployees(restaurantId:number) {
    const {data: employeesData, isLoading: isEmployeesLoading, error: employeesError} = useSWR( [EmployeesAPI.employeeKey,restaurantId], ([_key,id]) => EmployeesAPI.getAll(id));
    const {mutate} = useSWRConfig();
    const {errorToast, successToast} = useToasts();
    const useFormParams = useForm<Employee | EmployeeResponse>();
    const editEmployeeForm = useForm<EmployeeResponse>();

    useEffect(() => {
        if(employeesData?.data) {
            useFormParams.reset()
            editEmployeeForm.reset();
        }
    }, [ employeesData?.data, useFormParams, editEmployeeForm]);

    const createEmployee = useCallback(async() => {
         if(!employeesData) return;
          useFormParams.setValue("restaurant_id",restaurantId);
          try {
              await EmployeesAPI.create(useFormParams.getValues());
              await mutate([EmployeesAPI.employeeKey, restaurantId], useFormParams.getValues())
              useFormParams.reset();
            successToast('successFeedbacks.data_success')
        } catch(e:any) {
          const {status} = e.response as AxiosError;
          if(status === 403){
            return errorToast('errors.forbidden')
          }
          return errorToast('errors.internal_error')
        }
    },[employeesData, mutate, useFormParams])

    const handleEditClick = useCallback((emp?: EmployeeResponse) => {
      if (!emp) return;
      editEmployeeForm.setValue("id", emp.id);
      editEmployeeForm.setValue("name", emp.name);
      editEmployeeForm.setValue("salary_type", emp.salary_type);
      editEmployeeForm.setValue("department", emp.department);
      editEmployeeForm.setValue("salary", emp.salary);
      editEmployeeForm.setValue("salary_time_frame", emp.salary_time_frame);

  }, [editEmployeeForm])


    const saveEditedEmployee = async() => {
      try{
          await EmployeesAPI.edit(editEmployeeForm.getValues());
          await mutate([EmployeesAPI.employeeKey, restaurantId], editEmployeeForm.getValues())
      } catch(e){
        errorToast('errors.internal_error')
      }
    }

    const deleteEmployee = useCallback(async (id: number, index: number) => {

      const success = await EmployeesAPI.deleteById(id);
        if (success && employeesData?.data) {

          await mutate([EmployeesAPI.employeeKey,restaurantId], (employeesData?: RequestResponseType<Employee[]> ) => {
            if (employeesData?.data) {
              const updatedData = [...employeesData.data];
              updatedData.splice(index,1);
              return { ...employeesData, data: updatedData };
            }
            return employeesData;
          }, false);
        } else {
            errorToast('Could not delete employee '+id);
        }
      },[employeesData?.data,mutate, EmployeesAPI.deleteById]);
    
      const getEmployeeById=(id:number) => {
        return employeesData?.data.filter((emp) => +id === +emp.id)[0];
      }

    return {
        useFormParams,
        employees: employeesData?.data,
        isEmployeesLoading,
        employeesError,
        createEmployee,
        deleteEmployee,
        getEmployeeById,
        handleEditClick,
        editEmployeeForm,
        saveEditedEmployee
    };

}


