import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import useRestaurantValidation from './useRestaurantValidation.hook';
import { RestaurantRequest } from '../api/request.types';
import { RestaurantAPI } from '../api/restaurant.api';
import { useToasts } from './useToasts.hook';
import { useLocalization } from './useLocalization.hook';

export default function useRestaurant(restaurantId?:number) {
    const {id} = useRestaurantValidation(restaurantId);
    const {data: restaurantData, isLoading: restaurantDataLoading, error: restaurantDataError, mutate} = useSWR(id ? [ `${id}`, 'getRestaurantData' ] : null, ([id]) => RestaurantAPI.getOne(+id));
    const useFormParams = useForm<RestaurantRequest>();
    const {successToast, errorToast} = useToasts();
    const {t} = useLocalization();
    useEffect(() => {
        if (restaurantData?.data) {
            useFormParams.reset(restaurantData?.data);
        }
    }, [ restaurantData?.data, useFormParams ]);

    const updateRestaurant = async () => {
        if (!id) return;
        try{
            const result = await RestaurantAPI.updateRestaurant(+id, useFormParams.getValues());
            await mutate(result);
            successToast(t('successFeedbacks.data_success'))
        } catch(e){
            errorToast('errors.internal_error')
        }
    };

    return {
        useFormParams,
        restaurant: restaurantData?.data,
        restaurantLoading: restaurantDataLoading && !restaurantDataError,
        updateRestaurant,
    };

}