import useSWR from "swr";
import { DishesAPI } from "../api/dishes.api";
import useRestaurantValidation from "./useRestaurantValidation.hook";
import { DishesRequest } from "../api/request.types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "./useToasts.hook";

export function useDishes(restaurantId:number) {
    const {id} = useRestaurantValidation(restaurantId);
    const {data: dishesData, isLoading: isDishesLoading, error: dishesError, mutate} = useSWR( id? ['getDishes',+id!] : null, 
        ([_key,resId]) => DishesAPI.getByRestaurant(resId));
    const [dirtyFields,setDirtyFields] = useState<DishesRequest[]>([]);
    const formActions = useForm<DishesRequest[]>({
      defaultValues: {...dishesData?.data!}
    });
    const {errorToast,successToast} = useToasts()

    useEffect(()=> {
      formActions.reset({...dishesData?.data!})
    },[ dishesData])
    

    const onSelectChange = (index: number, value: "relational" | "literal") => {
        formActions.setValue(`${index}.calculation_type`, value);
        formActions.setValue(`${index}.id`, dishesData?.data[index].id! );
        if(dishesData?.data[index].calculation_type === value ) {
          dirtyFields.splice(index,1);
        }else {
        dirtyFields[index] = formActions.getValues()[index];
        setDirtyFields([...dirtyFields]);
      }
      };

      const onPriceChange = (index: number, value: number, field: 'incomes' | 'expenses') => {
        formActions.setValue(`${index}.${field}`, value);
        formActions.setValue(`${index}.id`, dishesData?.data[index].id! );
        //If default value
        if(dishesData?.data[index][field] === value ) {
          dirtyFields.splice(index,1);
        } else {
        //If new value (dirty)
        dirtyFields[index] = formActions.getValues()[index];
        setDirtyFields([...dirtyFields]);
        }
    };

    const saveChanges = async () => {
    try {
      await DishesAPI.updateByRestaurant(restaurantId, dirtyFields);
      await mutate()
     successToast('successFeedbacks.data_success')
    } catch (e) {
    errorToast('errors.internal_error')
  }
    }

    return {
        dishes: dishesData?.data,
        isDishesLoading,
        dishesError,
        formActions,
        saveChanges,
        onSelectChange,
        onPriceChange,
        dirtyFields
    }
}
