import { Box } from '@chakra-ui/react'
import { Chart, registerables } from 'chart.js';
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component';
import dayjs from 'dayjs';

Chart.register(...registerables);


type Props = {
    dates: Date[],
    values: number[]
}

const DistributionByDateGraph = ({ dates, values }: Props) => {

    const config: any = {
        data: {
            labels: dates?.map((date) => dayjs(date).format('DD.MM')),
            datasets: [{
                backgroundColor: "white",
                borderRadius: 10,
                data: values
            },
            ]
        },
        plugins: {
            legend: { display: false },
        },
        options: {
            maintainAspectRatio: false,
            responsive: true,
            ticks: {
                font: {
                    family: `'assistant',sans-serif`,
                    weight: 'bold'
                },
            },

            scales: {
                x: { display: true, ticks: { display: true, color: 'white' }, grid: { display: false } },
                y: { display: true, ticks: { display: true, color: 'white' }, grid: { display: false } }
            },
            plugins: { legend: { display: false } },
        },
    }
    return (
        <InfoBoxGrid w='full' h={['350px', '375px', '400px', '475px']} >
            <Bar {...config} ></Bar>
        </InfoBoxGrid >
    )
}

export default DistributionByDateGraph