import React from "react";
import { Box, Heading, Link } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Link as ReactLink } from "react-router-dom";
import { useEmployeesCosts } from "../../hooks/useEmployeesCosts.hook";
import { useLocalization } from "../../hooks/useLocalization.hook";
import { calcStartOfPickedDateMonth, dates } from "../../utils/dates.util";
import { formatNumber } from "../../utils/formatNumber.util";
import { InfoBox } from "../ui-components/infoBox.component";
import { InfoBoxGrid } from "../ui-components/InfoBoxGrid.component";
import { useGlobalDateContext } from "../../contexts/DateContext";

type Props = {
	showLink?: boolean;
	restaurantId: number;
};

function EmployeesCosts({ showLink, restaurantId }: Props) {
	const { t } = useLocalization();
    const { selectedDate } = useGlobalDateContext();
	const { data: yesterdayData, isLoading: isYesterdayLoading } =
		useEmployeesCosts(restaurantId, selectedDate,selectedDate);
	const { data: startOfMonthData, isLoading: isStartOfMonthLoading } =
		useEmployeesCosts(restaurantId, calcStartOfPickedDateMonth(selectedDate),selectedDate);
	return (
		<Box>
			<Box
				w={"100%"}
				display={"flex"}
				flexDir={"row"}
				justifyContent={"space-between"}
			>
				<Heading size={"md"} mb={4}>
					{t("expensesKeys.expenses_employees")}
				</Heading>
				{showLink && (
					<Link
						variant="secondary-title"
						as={ReactLink}
						to={`${location.pathname}/expenses`}
					>{`${t("routingKeys.toAllExpenses")} >`}</Link>
				)}
			</Box>
			<InfoBoxGrid w="100%">
				<InfoBox
					direction="column"
					isLoading={isYesterdayLoading}
					normalText={t("dateAndTimeKeys.yesterday")}
					boldText={formatNumber({ number: yesterdayData?.data, t })}
				/>
				<InfoBox
					direction="column"
					isLoading={isStartOfMonthLoading}
					normalText={t("dateAndTimeKeys.from_month_start")}
					boldText={formatNumber({ number: startOfMonthData?.data, t })}
				/>
			</InfoBoxGrid>
		</Box>
	);
}

export default EmployeesCosts;
