import { Box, Button, Heading } from '@chakra-ui/react';
import { useLocalization } from '../hooks/useLocalization.hook';
import useAuth from '../hooks/useAuth.hook';

export default function Page404() {
    const { t } = useLocalization();
    const { navUser } = useAuth();

    return (
        <Box display={'grid'} w='full' h={'80vh'} placeItems={'center'}>
            <Box>
                <Heading fontSize={[80, 150, 300]}>404</Heading>
                <Heading size={['xl', '2xl', '3xl']}>{`${t('404.header')} ${t('404.body')}`}</Heading>
                <Button onClick={() => navUser()} mt={5}>{t('404.button')}</Button>
            </Box>
        </Box>
    );
}