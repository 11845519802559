import { Stack, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useMemo } from "react";
import useUserRestaurants from "../../hooks/useUserRestaurants.hook";
import { matchPath, useLocation } from "react-router-dom";
import { useLocalization } from "../../hooks/useLocalization.hook";
import breakpoints from "../../styles/breakpoints";
import useSWR from "swr";
import { RestaurantsDailyAPI } from "../../api/restaurantsDaily.api";
import dayjs from "dayjs";

function UserRestaurants() {
	const { restaurants, isLoading, switchRestaurant, findById } =
		useUserRestaurants();
	const { direction, t } = useLocalization();
	const [isMobile] = useMediaQuery(breakpoints.isMobile);
	const location = useLocation();
	const match = matchPath({ path: "/restaurants/:id/*" }, location.pathname);
	const restaurantId = match?.params.id;
	const { data: lastUpdated } = useSWR(
		restaurantId ? [restaurantId, "getLastUpdated"] : null,
		([id]) => RestaurantsDailyAPI.getLastUpdated(+id)
	);

	const defaultValue = useMemo(() => {
		if (!restaurantId) return;
		return findById(+restaurantId)?.id;
	}, [location.pathname,isLoading,restaurantId]);
	if (isLoading || !defaultValue) return <></>;

	const handleRestaurantChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const newId = event.currentTarget.value;
		const targetRoute = location.pathname.replace(String(restaurantId), newId);
		switchRestaurant(+newId, targetRoute);
	};

	return (
		<Stack
			w="full"
			spacing={"5px"}
			flexDirection={"column"}
			color={"black"}
			textAlign={direction === "rtl" ? "right" : "left"}
			alignItems={"center"}
		>
			<select
				style={{
					width: isMobile ? "60vw" : "100%",
					border: "none",
					WebkitAppearance: "none",
					MozAppearance: "none",
					background: "inherit",
				}}
				value={defaultValue}
				onChange={handleRestaurantChange}
			>
				{restaurants?.map((restaurant) => {
					return (
						<option key={restaurant.id} value={restaurant.id}>
							{restaurant.name}
						</option>
					);
				})}
			</select>
			{lastUpdated?.data && (
				<Text mb={"5px"} color={"#919191"} fontSize={"sm"}>{`${t(
					"last_updated_at"
				)}: ${dayjs(lastUpdated?.data).format("DD/MM").toString()}`}</Text>
			)}
		</Stack>
	);
}

export default UserRestaurants;
