import { LoginRequest } from './request.types';
import { MeResponse } from './response.types';
import { request } from '../utils/api.util';

export class AuthApi {
    static async me() {
        return await request<MeResponse>({
            uri: 'auth/me',
            method: 'GET',
        });
    }

    static async login(params:LoginRequest) {
        await request({
            uri: 'auth/login',
            method: 'POST',
            body: params
        });
    }

    static async register(params:LoginRequest) {
        await request({
            uri: 'auth/register',
            method: 'POST',
            body: params
        });
    }

    static async logout() {
        await request({
            uri: 'auth/logout',
            method: 'POST',
        });
    }
}