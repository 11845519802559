import React from 'react';
import useEmployees from '../hooks/useEmployees.hook'
import { Box, Button, Heading, Select } from '@chakra-ui/react';
import { Departments, Employee, SalaryTimeFrame, SalaryTypes } from '../api/models.api';
import FormItem from './ui-components/formItem.component';
import { useLocalization } from '../hooks/useLocalization.hook';
import "../styles/rtl-select.css"

type Props = {
    restaurantId: number
}

const CreateEmployee = React.memo(({ restaurantId }: Props) => {
    const { useFormParams, createEmployee } = useEmployees(restaurantId);
    const { t } = useLocalization();

    return (
        <Box p={[0, 10]} w={['', '', '100%', '50%']}>
            <Heading mb={10}>{t('add') + " " + t('employeeAttributesKeys.employees')}</Heading>
            <Box as="form" onSubmit={useFormParams?.handleSubmit(createEmployee)} display={'flex'}
                flexDir={'column'} gap={4} mb={5}>

                <FormItem<Employee> validation={{ required: true }} name='name' displayName={t('employeeAttributesKeys.name')} formActions={useFormParams} />
                <Select defaultValue={Object.values(Departments)[0]} {...useFormParams.register("department")}>
                    {
                        Object.entries(Departments).map(([key, value]) => <option value={value} key={value}>
                            {t(`departments.${key}`)}
                        </option>)
                    }
                </Select>
                <Select defaultValue={Object.values(SalaryTypes)[0]}{...useFormParams.register("salary_type")}>
                    {
                        Object.entries(SalaryTypes).map(([key, value]) => <option value={value} key={value}>
                            {t(`salaryTypes.${key}`)}
                        </option>)
                    }
                </Select>
                <Select defaultValue={Object.values(SalaryTimeFrame)[0]}{...useFormParams.register("salary_time_frame")}>
                    {
                        Object.entries(SalaryTimeFrame).map(([key, value]) => <option value={value} key={value}>
                            {t(`salaryTimeFrame.${key}`)}
                        </option>)
                    }
                </Select>
                <FormItem<Employee> validation={{ required: true }} name='salary' inputMode='numeric' displayName={t('employeeAttributesKeys.salary')} formActions={useFormParams} />
                <Box><Button isLoading={useFormParams.formState.isSubmitting} mt={5} type="submit">{t('add')}</Button></Box>

            </Box>


        </Box>
    )
});

export default CreateEmployee