import React from "react";
import { Route, Routes } from "react-router-dom";
import useAuth, { AuthState } from "../../hooks/useAuth.hook";
import ExpensesPage from "../../pages/expenses.page";
import IncomePage from "../../pages/income.page";
import Login from "../../pages/login.page";
import MainPage from "../../pages/main.page";
import Page404 from "../../pages/page404";
import Restaurants from "../../pages/restaurants.page";
import SettingsPage from "../../pages/settings.page";
import ShiftReport from "../../pages/shiftReport.page";
import WelcomePage from "../../pages/welcomePage.page";
import { RestaurantEditComponent } from "../ui-components/restaurantEdit.component";

interface NavMap {
	route: string;
	component: JSX.Element;
	authState?: AuthState[];
}

const routes: NavMap[] = [
	{ route: "/", component: <WelcomePage /> },
	{
		route: "/restaurants",
		component: <Restaurants />,
		authState: [AuthState.ADMIN, AuthState.SHIFT_MANAGER],
	},
	{
		route: "/restaurants/:id",
		component: <MainPage />,
		authState: [AuthState.ADMIN],
	},
	{
		route: "/restaurants/:id/edit",
		component: <RestaurantEditComponent />,
		authState: [AuthState.ADMIN],
	},
	{
		route: "/login",
		component: <Login />,
		authState: [AuthState.GUEST, AuthState.ADMIN, AuthState.SHIFT_MANAGER],
	},
	{
		route: "/restaurants/:id/shiftreport",
		component: <ShiftReport />,
		authState: [AuthState.ADMIN, AuthState.SHIFT_MANAGER],
	},
	{
		route: "/restaurants/:id/incomes",
		component: <IncomePage />,
		authState: [AuthState.ADMIN],
	},
	{
		route: "/restaurants/:id/settings",
		component: <SettingsPage />,
		authState: [AuthState.ADMIN, AuthState.SHIFT_MANAGER],
	},
	{
		route: "/restaurants/:id/expenses",
		component: <ExpensesPage />,
		authState: [AuthState.ADMIN],
	},
];

export default function AppRouter() {
	const { authStates } = useAuth();

	return (
		<Routes>
			{!authStates.includes(AuthState.LOADING) && (
				<>
					{routes.map((x, i) =>
						!x.authState ||
						x.authState?.some((role) => authStates.includes(role)) ? (
							<Route key={i} path={x.route} element={x.component} />
						) : null
					)}
					<Route path={"*"} element={<Page404 />} />
				</>
			)}
		</Routes>
	);
}
