import React, { useMemo, useRef } from "react";
import {
	ArrowBackIcon,
	ArrowForwardIcon,
	HamburgerIcon,
} from "@chakra-ui/icons";
import {
	Box,
	useDisclosure,
	Stack,
	IconButton,
	Button,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerCloseButton,
	DrawerBody,
	DrawerFooter,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Footer from "./footer.component";
import { navItem } from "./navItem.component";
import CleanProfitBanner from "./profitBanner.component";
import { navs } from "./routes";
import { useGlobalDateContext } from "../../../contexts/DateContext";
import useAuth, { AuthState } from "../../../hooks/useAuth.hook";
import useCleanProfit from "../../../hooks/useCleanProfit.hook";
import { useLocalization } from "../../../hooks/useLocalization.hook";
import { formatNumber } from "../../../utils/formatNumber.util";
import DatePicker from "../../ui-components/datePicker.component";
import { calcStartOfPickedDateMonth, dates } from "../../../utils/dates.util";

function MobileSideBar({ id }: { id: string }) {
	const { me, authStates } = useAuth();
	const navigate = useNavigate();
	const { t, direction } = useLocalization();
	const { onClose, isOpen, onOpen } = useDisclosure();
	const btnRef = useRef();
	const { selectedDate, updateSelectedDate } = useGlobalDateContext();

	const dailyProfitText = `${t("incomeKeys.totalCleanProfit")} ${t(
		"dateAndTimeKeys.yesterday"
	)}:`;
	const startOfMonthProfitText = `${t("incomeKeys.totalCleanProfit")} ${t(
		"dateAndTimeKeys.from_month_start"
	)}:`;
	const ArrowIcon = () =>
		direction === "rtl" ? <ArrowBackIcon /> : <ArrowForwardIcon />;
	//Dont show on login page
	const isVisible = useMemo(
		() => location.pathname !== "/login",
		[location.pathname]
	);

	//Dont open drawer at homepage and login
	const openDrawer = () => {
		if (location.pathname === "/" || location.pathname === "/login") {
			return;
		}
		onOpen();
	};

	const { totalCleanProfit } = useCleanProfit(+id!, selectedDate,selectedDate);

	return (
		<>
			<Stack
				flexDir="row"
				display={!isVisible ? "none" : ""}
				zIndex={999}
				position="fixed"
				bottom={0}
				justifySelf="center"
				py={5}
			>
				{me && location.pathname !== "/" ? (
					<Box>
						<IconButton
							aria-label="Mobile menu button"
							px={7}
							icon={<HamburgerIcon />}
							ref={btnRef as any}
							onClick={openDrawer}
						/>
						<Button w={"fit-content"} px={7}>
							{`${t("incomeKeys.totalCleanProfit")}: ${formatNumber({
								number: totalCleanProfit,
								t,
								allowZero: true,
								maxDigits: 1,
							})}`}{" "}
						</Button>
					</Box>
				) : (
					<Box>
						<Button
							w={"50vw"}
							px={7}
							ref={btnRef as any}
							onClick={() => navigate("/login")}
						>
							<Box>
								{t("getStarted")} <ArrowIcon />
							</Box>
						</Button>
					</Box>
				)}
				<Drawer
					isOpen={isOpen}
					placement={direction === "rtl" ? "right" : "left"}
					onClose={onClose}
					finalFocusRef={btnRef as any}
				>
					<DrawerOverlay />
					<DrawerContent
						bgGradient={"linear(160deg, primary,primary,secondary, secondary)"}
					>
						<DrawerHeader>
							<DrawerCloseButton color={"white"} right={"90%"} />
							<ReactSVG src="/svg/alfred_white_logo.svg" />
						</DrawerHeader>
						<DrawerBody>
							<Box display={"flex"} flexDir={"column"} w={"100%"} gap={1}>
								{navs.map((x, i) => navItem(x, i, authStates, id, onClose))}
								{authStates.includes(AuthState.ADMIN) && (
									<>
										<CleanProfitBanner
											startDate={selectedDate}
											endDate={selectedDate}
											text={dailyProfitText}
											id={+id!}
										/>
										<CleanProfitBanner
											startDate={calcStartOfPickedDateMonth(selectedDate)}
											endDate={selectedDate}
											text={startOfMonthProfitText}
											id={+id!}
										/>
									</>
								)}
							</Box>
						</DrawerBody>
						<DrawerFooter>
							<Footer />
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			</Stack>
		</>
	);
}

export default MobileSideBar;
