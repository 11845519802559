import useSWR from "swr";
import { RestaurantsDailyAPI } from "../api/restaurantsDaily.api";

export default function useCleanProfit(
	restaurantId: number,
	startDate: Date,
	endDate: Date
) {
	const { data: totalCleanProfit } = useSWR(
		restaurantId ? [restaurantId, `employeesCostsDay${startDate}-${endDate}`] : null,
		([id]) =>
			RestaurantsDailyAPI.getCleanProfit(id, { start: startDate, end: endDate })
	);
	return {
		totalCleanProfit: totalCleanProfit?.data,
	};
}
