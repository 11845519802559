import { useMemo } from "react";
import { NavItemInfo } from "./routes";
import { matchPath, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Box, Text } from "@chakra-ui/react";
import { AuthState } from "../../../hooks/useAuth.hook";
import { useLocalization } from "../../../hooks/useLocalization.hook";

export const navItem = (props: NavItemInfo, i: number, currentAuthStates: AuthState[], id?: string, callback?: () => void) => {
    const { t } = useLocalization();
    const navigate = useNavigate();
    const isActivePage = useMemo(() => {
        return (location.pathname === props.route) || matchPath({ path: props.route }, location.pathname)
    }, [location.pathname])

    const match = matchPath({ path: "/restaurants/:id/*" }, location.pathname);
    const restaurantId = match?.params.id;

    if (!restaurantId || location.pathname === '/login' || props.authStates && !props.authStates?.some((role) => currentAuthStates.includes(role))) {
        return null;
    }
    const navRoute = id ? props.route.replace(":id", id) : props.route;

    const handleClick = () => {
        navigate(navRoute)
        callback && callback();
    }

    return (
        <Box key={i} onClick={handleClick} _hover={{ cursor: 'pointer' }} w={'100%'} gap={'10px'} display={'flex'} p={'20px'} color={isActivePage ? 'black' : 'white'} bg={isActivePage ? 'white' : 'transparent'} justifyContent={'start'} alignItems={'center'} rounded={'sm'} >
            <ReactSVG style={{ fill: isActivePage ? 'black' : 'white' }} src={props.icon} />
            <Text fontWeight={600}>{t(props.text)}</Text>
        </Box >
    );
};