import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    FormControl,
    Select,
    Box,
    Flex,
    Stack,
} from '@chakra-ui/react';
import { Departments, Employee, SalaryTimeFrame, SalaryTypes } from '../../api/models.api';
import { useLocalization } from '../../hooks/useLocalization.hook';
import useEmployees from '../../hooks/useEmployees.hook';
import FormItem from '../ui-components/formItem.component';
import { EmployeeResponse } from '../../api/response.types';
import { useEffect } from 'react';

type Props = {
    employee: EmployeeResponse
    isOpen: boolean
    restaurantId: number
    onClose: () => void
}

function EditEmployeeModal({ employee, isOpen, onClose, restaurantId }: Props) {
    const { t } = useLocalization();
    const { saveEditedEmployee, editEmployeeForm, handleEditClick } = useEmployees(restaurantId);

    useEffect(() => {
        handleEditClick(employee);
    }, [employee])

    const handleSubmit = async () => {
        await saveEditedEmployee()
        onClose();
    }

    return (
        <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
            <ModalOverlay />
            <ModalContent dir='rtl' textAlign={'center'} as='form' onSubmit={editEmployeeForm?.handleSubmit(handleSubmit)}>
                <ModalHeader>{t('edit') + " " + t('employeeAttributesKeys.employee')}</ModalHeader>
                <ModalBody>
                    <FormControl>
                        <Stack spacing={4}>
                            <FormItem<Employee> name='name' inputMode='numeric' displayName={t('employeeAttributesKeys.name')} formActions={editEmployeeForm} validation={{ required: true }} />
                            <Select {...editEmployeeForm.register("department")}>
                                {
                                    Object.entries(Departments).map(([key, value]) => <option value={value} key={value}>
                                        {t(`departments.${key}`)}
                                    </option>)
                                }
                            </Select>
                            <Select {...editEmployeeForm.register("salary_type")}>
                                {
                                    Object.entries(SalaryTypes).map(([key, value]) => <option value={value} key={value}>
                                        {t(`salaryTypes.${key}`)}
                                    </option>)
                                }
                            </Select>
                            <Select {...editEmployeeForm.register("salary_time_frame")}>
                                {
                                    Object.entries(SalaryTimeFrame).map(([key, value]) => <option value={value} key={value}>
                                        {t(`salaryTimeFrame.${key}`)}
                                    </option>)
                                }
                            </Select>
                            <FormItem<Employee> name='salary' inputMode='numeric' displayName={t('employeeAttributesKeys.salary')} formActions={editEmployeeForm} validation={{ required: true }} />
                        </Stack>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button type='submit'>
                        {t('save')}
                    </Button>
                    <Button mr={3} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default EditEmployeeModal