import { RestaurantResponse } from './response.types';
import { request } from '../utils/api.util';

export class UserAPI {

    static async getUserRestaurants(id:number) {
        return request<RestaurantResponse[]>({
            uri: `users/${id}/restaurants`,
            method: 'GET'
        });
    }
}