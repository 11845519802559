import React, { createContext, useContext, useState } from "react";
import { dates } from "../utils/dates.util";

interface PickedDateContextInterface {
	selectedDate: Date;
	updateSelectedDate: (newSelectedDate: Date) => void;
}


const globalDateContext = createContext<PickedDateContextInterface | undefined>(undefined);

export function useGlobalDateContext() {
    const context = useContext(globalDateContext);
    if (!context) {
        throw new Error("useContext must be used within a MyContextProvider");
    }
    return context;

}

interface DateContextProps {
	children:JSX.Element
}

//Context Provider
export function GlobalDateContextProvider({children}:DateContextProps) {
    const [ chosenDate, setChosenDate ] = useState<Date>(dates.yesterday);

    const contextValue: PickedDateContextInterface = {
        selectedDate: chosenDate,
        updateSelectedDate: setChosenDate,
    };

    return (
        <globalDateContext.Provider value={contextValue}>
            {children}
        </globalDateContext.Provider>
    );
}
