import { Box, Button, Heading } from '@chakra-ui/react'
import { useLocalization } from '../../hooks/useLocalization.hook'
import useRestaurantDailyData from '../../hooks/useRestaurantDailyData.hook';


function NoShiftData({ selectedCurrentDate, restaurantId }: { selectedCurrentDate: Date, restaurantId: number }) {
  const { t } = useLocalization();
  const { loadRestaurantData } = useRestaurantDailyData(restaurantId);
  return (
    <Box display={'grid'} placeItems={'center'}>
      <Heading textAlign={"center"} size={"md"}>{`${selectedCurrentDate.toLocaleDateString()} -${t('errors.noData')}`}</Heading>
      <Button mt={'5vh'} size={'lg'} onClick={() => loadRestaurantData()}>{t('load_data')}</Button>
    </Box>
  )
}

export default NoShiftData