import { Box, Button, Heading, Textarea, Input, Flex } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from '../../hooks/useLocalization.hook'
import { useForm } from 'react-hook-form'
import { RestaurantsDaily } from '../../api/models.api'
import { InfoBoxGrid } from '.././ui-components/InfoBoxGrid.component'
import { InfoBox } from '.././ui-components/infoBox.component'


type Props = {
    editOrCreateDescription: (txt?: string) => Promise<void>
    currentDescription?: string
}

function ShiftDescription({ editOrCreateDescription, currentDescription }: Props) {
    const { t } = useLocalization();
    const { handleSubmit, register, reset, formState, setValue } = useForm<RestaurantsDaily>();
    const [edit, setEdit] = useState(!currentDescription);


    const onSubmit = useCallback(async (value: RestaurantsDaily) => {
        await editOrCreateDescription(value.description);
        reset()
        setEdit(false);
    }, [editOrCreateDescription, reset])

    const toggleEdit = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setValue("description", currentDescription);
        setEdit(true)
    }, [])

    return (
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <Heading size="md" mb={4}>{t('shiftReportKeys.shiftDescription')}</Heading>
            {!edit ?
                <Flex w='full'>
                    <Input mb={'5vh'} w={['60%', '40%', '20%']} readOnly variant="filled" size={"sm"} value={currentDescription} />
                    <Button type='button' mx={5} onClick={toggleEdit}>{t('edit')}</Button>
                </Flex>
                : <Flex w={'full'} direction={['column', 'row']}>
                    <InfoBoxGrid w={'50%'}>
                        <InfoBox direction='column-reverse'>
                            <Textarea w={'100%'} {...register('description')} rows={1} cols={100} border={"none"} placeholder={t('type')} fontWeight={"bold"} />
                        </InfoBox>
                    </InfoBoxGrid>
                    <Button isLoading={formState.isSubmitting} alignSelf={['end', 'center']} mt={[3, 0]} type="submit" mx={5}>{t('save')}</Button>
                </Flex>
            }
        </Box>
    )
}

export default ShiftDescription