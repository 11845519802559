import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { useLocalization } from "../../hooks/useLocalization.hook";
import { formatNumber } from "../../utils/formatNumber.util";
import { InfoBox } from "../ui-components/infoBox.component";
import { InfoBoxGrid } from "../ui-components/InfoBoxGrid.component";


type Props = {
    yesterdayExpenses: number
    startOfMonthExpenses: number
}

function TotalExpenses({ startOfMonthExpenses: startOfMonthData, yesterdayExpenses: yesterdayData }: Props) {
    const { t } = useLocalization();


    return (
        <Box>
            <Box w={"100%"} display={"flex"} flexDir={"row"} justifyContent={"space-between"}>
                <Heading size={"md"} mb={4}>{t("expensesKeys.expenses_total")}</Heading>
            </Box>
            <InfoBoxGrid w={"100%"}>
                <InfoBox isLoading={yesterdayData === null} direction="column" normalText={t("dateAndTimeKeys.yesterday")} boldText={formatNumber({ number: yesterdayData, t })} />
                <InfoBox isLoading={startOfMonthData === null} direction="column" normalText={t("dateAndTimeKeys.from_month_start")} boldText={formatNumber({ number: startOfMonthData, t })} />
            </InfoBoxGrid>
        </Box>
    );
}

export default TotalExpenses;
