import React, { useState } from "react";
import { Box, Heading, Spinner, useDisclosure } from "@chakra-ui/react";
import AssignEmployeesToShift from "./assignEmployeesToShift.component";
import EmployeeShiftModal from "./employeeShiftModal.component";
import ShiftEmployeesTable from "./shiftEmployeesTable.component";
import { EmployeeShiftWithEmployeesResponse } from "../../api/response.types";
import { useLocalization } from "../../hooks/useLocalization.hook";
import useShifts from "../../hooks/useShifts.hook";

type Props = {
    restaurantDailyId: number,
    restaurantId: number
}

function EndOfShiftForm({ restaurantDailyId, restaurantId }: Props) {
    const { t } = useLocalization();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { employeeShiftData, isEmployeeShiftDataLoading, deleteEmployeeFromShift } = useShifts(restaurantDailyId);
    const [ employeeData, setEmployeeData ] = useState<EmployeeShiftWithEmployeesResponse>();

    if (isEmployeeShiftDataLoading) { return <Spinner />; }

    const handleEmployeeClick = (emp: EmployeeShiftWithEmployeesResponse) => {
        setEmployeeData(emp);
        onOpen();
    };


    return (
        <Box>
            <Heading size="md" my={4}>{t("shiftReportKeys.yesterdayWorkers")}</Heading>
            <ShiftEmployeesTable data={employeeShiftData?.data} handleEmployeeClick={handleEmployeeClick} handleDelete={deleteEmployeeFromShift} />
            {isOpen && <EmployeeShiftModal onClose={onClose} employeeShiftData={employeeData!} isOpen={isOpen} restaurantDailyId={restaurantDailyId} />}
            <AssignEmployeesToShift restaurantDailyId={restaurantDailyId} restaurantId={restaurantId} />
        </Box >
    );
}

export default EndOfShiftForm;