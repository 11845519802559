import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type LanguageDirection = 'rtl' | 'ltr';

export function useLocalization() {
    const { i18n, t } = useTranslation();
    const direction:LanguageDirection = i18n.language === 'he' ? 'rtl' : 'ltr';

    const changeLanguage = useCallback(() => {
        const newLang = direction === 'rtl' ? 'en' : 'he';
        i18n.changeLanguage(newLang);
        localStorage.setItem('lang', newLang);
    }, [ direction, i18n ]);

    return { 
      direction,
      t: t as (value:string) => string,
      changeLanguage
       };
}
