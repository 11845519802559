import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import useCleanProfit from "../../../hooks/useCleanProfit.hook";
import { useLocalization } from "../../../hooks/useLocalization.hook";
import { formatNumber } from "../../../utils/formatNumber.util";

interface CleanProfitBannerProps {
    id: number;
	startDate: Date;
    endDate:Date;
	text: string;
}

//NOTICE:
// for 08/03/yyyy server expects a start date 08/03/yyyy 
// and the end date will be also 08/03/yyyy 

const CleanProfitBanner = ({id, startDate, text,endDate}: CleanProfitBannerProps) => {
    const { t } = useLocalization();
    const { totalCleanProfit } = useCleanProfit(id, startDate,endDate);
    if (totalCleanProfit === null ) { return <></>; }

    return (
        <Box bgColor={"#14B7D3"} borderRadius={"50px"} w='100%' mb={8}>
            <Flex py={4} alignItems={'center'} justifyContent={"space-around"} fontWeight={600} fontSize={"sm"} px={2}>
                <Text flex={1} >{text}</Text>
                <Text >{formatNumber({ number: totalCleanProfit, allowZero: true, t, maxDigits: 1 })}</Text>
            </Flex>
        </Box>
    );

};

export default CleanProfitBanner;