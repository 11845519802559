import useSWR from "swr";
import { DishesAPI } from "../api/dishes.api";
import { useForm } from "react-hook-form";

export function useCategories(restaurantId: number) {
    
    const { data, isLoading } = useSWR([restaurantId], ([id]) => DishesAPI.getCategoriesByRestaurant(id));
    const formActions = useForm<Array<{ price: number }>>();
 
    // useEffect(()=> {
    //     data?.data.forEach((category,index) => {
    //       formActions.setValue(`${index}.expenses`, expenses ?? 0);
    //     });
    //   },[ data])

    return {
        categories: data?.data,
        isLoading,
        formActions
    }
    
}