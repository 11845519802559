import React, { useContext } from "react";
import {
	Flex,
	GridItem,
	Spinner,
	Stack,
	useMediaQuery,
	Box,
	Grid,
	Heading,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Discounts from "../components/expenses-page-components/discounts.component";
import DishCosts from "../components/expenses-page-components/dishCosts.component";
import EmployeesCosts from "../components/expenses-page-components/employeesCosts.component";
import ExpensesAnalyticalData from "../components/expenses-page-components/expensesAnalyticsData.component";
import Refunds from "../components/expenses-page-components/refunds.component";
import TotalExpenses from "../components/expenses-page-components/totalExpenses.component";
import DeliveryIncomes from "../components/income-page-components/deliveryIncomes.component";
import GuestAmount from "../components/income-page-components/guestAmount.component";
import Gauge from "../components/income-page-components/incomeGague.component";
import IncomePerDayGraph from "../components/income-page-components/incomePerDayGraph.component";
import TotalIncomes from "../components/income-page-components/totalIncomes.component";
import { useGlobalDateContext } from "../contexts/DateContext";
import useRestaurant from "../hooks/useRestaurant.hook";
import useRestaurantDailyData from "../hooks/useRestaurantDailyData.hook";
import { useRestaurantDateRange } from "../hooks/useRestaurantDateRange.hook";
import breakpoints from "../styles/breakpoints";
import { calcStartOfPickedDateMonth, dates } from "../utils/dates.util";

const MainPage = () => {
	const { id } = useParams();
	const restaurantId = parseInt(id!);
	const [isMobile] = useMediaQuery(breakpoints.isMobile);
	const { selectedDate } = useGlobalDateContext();
	const { restaurantDailyData } = useRestaurantDailyData(restaurantId);
	const {
		restaurantDailyReportsData: startOfMonthData,
		restaurantDailyReportsDataLoading: isStartOfMonthDataLoading,
	} = useRestaurantDateRange(restaurantId, {
		startDate: calcStartOfPickedDateMonth(selectedDate),
		endDate: selectedDate,
	});
	const { restaurant, restaurantLoading } = useRestaurant(restaurantId);

	if (isStartOfMonthDataLoading || restaurantLoading) {
		return <Spinner />;
	}
	if (!startOfMonthData || !restaurant) {
		return <h1>Error</h1>;
	}

	if (isMobile) {
		return (
			<Stack spacing={"2vh"} justifyContent={"center"} w={"95vw"}>
				<TotalIncomes
					yesterdayData={restaurantDailyData?.shiftIncomes ?? 0}
					startOfMonthData={startOfMonthData?.dailyIncome}
					restaurant={restaurant}
					showLink
				/>
				<Gauge
					actual={startOfMonthData.dailyIncome}
					total={restaurant?.income_goal ?? 0}
				/>
				<GuestAmount
					startOfMonthAmount={startOfMonthData?.guestAmount}
					yesterdayAmount={restaurantDailyData?.analytics.guestAmount ?? 0}
				/>
				<DeliveryIncomes
					yesterdayData={restaurantDailyData?.analytics?.deliveryIncome ?? 0}
					startOfMonthData={startOfMonthData?.deliveryIncome}
					showLink
					yesterdayCount={restaurantDailyData?.analytics?.deliveryCount ?? 0}
					startOfTheMonthCount={startOfMonthData?.deliveryCount}
				/>
				<IncomePerDayGraph />
				<ExpensesAnalyticalData restaurantId={restaurantId} showLink />
				<EmployeesCosts restaurantId={restaurantId} showLink />
				<DishCosts
					yesterdayData={
						restaurantDailyData?.analytics?.totalDishesExpenses ?? 0
					}
					startOfMonthData={startOfMonthData?.totalDishesExpenses}
					showLink
				/>
				<Refunds
					yesterdayData={restaurantDailyData?.analytics?.totalRefund ?? 0}
					startOfMonthData={startOfMonthData?.totalRefund}
				/>
				<Discounts
					yesterdayData={restaurantDailyData?.analytics?.totalDiscount ?? 0}
					startOfMonthData={startOfMonthData?.totalDiscount}
				/>
				<TotalExpenses
					yesterdayExpenses={restaurantDailyData?.shiftExpenses ?? 0}
					startOfMonthExpenses={startOfMonthData?.expenses}
				/>
			</Stack>
		);
	}

	return (
		<>
			<Heading>{restaurant.name}</Heading>
			<Grid templateColumns={"repeat(8,1fr)"} columnGap={"20px"} rowGap={"3vh"}>
				<GridItem colSpan={8}>
					<TotalIncomes
						yesterdayData={restaurantDailyData?.shiftIncomes ?? 0}
						startOfMonthData={startOfMonthData?.dailyIncome}
						restaurant={restaurant}
						showLink
					/>
				</GridItem>
				<GridItem colSpan={8}>
					<Flex alignItems={"center"} w="full" columnGap={"20px"}>
						<Gauge
							actual={startOfMonthData.dailyIncome}
							total={restaurant?.income_goal ?? 0}
						/>
						<Box flex="1" mt={-5} style={{ minWidth: 0 }}>
							<IncomePerDayGraph />
						</Box>
					</Flex>
				</GridItem>
				<GridItem colSpan={8}>
					<DeliveryIncomes
						yesterdayData={restaurantDailyData?.analytics?.deliveryIncome ?? 0}
						startOfMonthData={startOfMonthData?.deliveryIncome}
						showLink
						yesterdayCount={restaurantDailyData?.analytics?.deliveryCount ?? 0}
						startOfTheMonthCount={startOfMonthData?.deliveryCount}
					/>
				</GridItem>
				<GridItem colSpan={8}>
					<GuestAmount
						startOfMonthAmount={startOfMonthData?.guestAmount}
						yesterdayAmount={restaurantDailyData?.analytics?.guestAmount ?? 0}
					/>
				</GridItem>
				<GridItem colSpan={8}>
					<ExpensesAnalyticalData restaurantId={restaurantId} showLink />
				</GridItem>
				<GridItem colSpan={3}>
					<EmployeesCosts restaurantId={restaurantId} />
				</GridItem>
				<GridItem colSpan={5}>
					<DishCosts
						yesterdayData={
							restaurantDailyData?.analytics?.totalDishesExpenses ?? 0
						}
						startOfMonthData={startOfMonthData?.totalDishesExpenses}
						showLink
					/>
				</GridItem>
				<GridItem colSpan={4}>
					<Refunds
						yesterdayData={restaurantDailyData?.analytics?.totalRefund ?? 0}
						startOfMonthData={startOfMonthData?.totalRefund}
					/>
				</GridItem>
				<GridItem colSpan={4}>
					<Discounts
						yesterdayData={restaurantDailyData?.analytics?.totalDiscount ?? 0}
						startOfMonthData={startOfMonthData?.totalDiscount}
					/>
				</GridItem>
				<GridItem colSpan={8}>
					<TotalExpenses
						yesterdayExpenses={restaurantDailyData?.shiftExpenses ?? 0}
						startOfMonthExpenses={startOfMonthData?.expenses}
					/>
				</GridItem>
			</Grid>
		</>
	);
};

export default MainPage;
