import { RestaurantRequest } from './request.types';
import { RestaurantResponse } from './response.types';
import { request } from '../utils/api.util';

export class RestaurantAPI {

    static async getOne(id:number) {
        return request<RestaurantResponse>({
            uri: `restaurants/${id}`,
            method: 'GET'
        });
    }

    static async updateRestaurant(id:number, body:RestaurantRequest) {
        return request<RestaurantResponse>({
            uri: `restaurants/${id}`,
            method: 'PUT',
            body
        });
    }
}