import React from "react";
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Heading,
	Input,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { LoginRequest } from "../api/request.types";
import useAuth, { AuthState } from "../hooks/useAuth.hook";
import { useLocalization } from "../hooks/useLocalization.hook";

export default function Login() {
	const loginForm = useForm<LoginRequest>();
	const { login, authStates, navUser } = useAuth();
	const { t } = useLocalization();

	if (!authStates.includes(AuthState.GUEST)) {
		navUser();
	}

	return (
		<Box
			my={40}
			maxW={"700px"}
			textAlign={"center"}
			mx={"auto"}
			as="form"
			onSubmit={loginForm.handleSubmit(() =>
				login(loginForm.getValues().email, loginForm.getValues().password)
			)}
		>
			<Heading mb={4}>{t("welcome")}</Heading>
			<FormControl gap={3} display={"flex"} flexDir={"column"} isRequired>
				<Box>
					<FormLabel>{t("email")}</FormLabel>
					<Input type="email" {...loginForm.register("email")} />
				</Box>
				<Box>
					<FormLabel>{t("password")}</FormLabel>
					<Input type="password" {...loginForm.register("password")} />
				</Box>
				<Button isLoading={loginForm.formState.isSubmitting} type="submit">
					{t("connect")}
				</Button>
			</FormControl>
		</Box>
	);
}
