import { extendTheme } from '@chakra-ui/react';

export const theme = (direction:string) => extendTheme({
    components: {
        Button: {
            variants: {
                solid: {
                    bg:"#9210D2",
                    borderRadius:"30px",
                    color:"white",
                    fontWeight: "600",
                    boxShadow: "0px 4px 4px 0px #C550FF inset, 0px -4px 4px 0px #7401AC inset",
                    _hover: {
                        bg:"#9210D2",
                        borderRadius:"30px",
                        color:"white",
                        fontWeight: "600"
                    }
                }
            },
        },
        Link: {
            variants:{
            "secondary-title": {
                color: "#919191",
                leadingTrim: "both",
                textEdge:"cap",
                cursor:"pointer"
            }
        }
        },
    },
    colors: {
        primary: '#14B7D3',
        secondary: '#9210D2',
    },
    fonts: {
        heading: '\'assistant\', sans-serif',
        body: '\'assistant\', sans-serif',
    },
    radii: {
        sm: '20px',
        md: '30px',        
    },
    fontWeights: {
        extraLight: 200,
        light: 300,
        normal: 400,
        semiBold: 500,
        bold: 600,
        extraBold: 900,
    },
    
    sizes: {
        sm: '16px',
        md: '18px',
    },
    fontSizes: {
        sm: '16px',
        md: '18px',
    },
    styles: {
        global: {
            body: {
                direction: direction,
            },
        },
    },
});