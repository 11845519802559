import useSWR from "swr";
import { RestaurantsDailyAPI } from "../api/restaurantsDaily.api";

type DateProps = {
	startDate?: Date;
	endDate: Date;
};

export function useRestaurantDateRange(
    restaurantId: number,
    selectedDates: DateProps
) {
    const {
        data: restaurantDailyReportsData,
        isLoading: restaurantDailyReportsDataLoading,
        error: restaurantDailyReportsDataError,
    } = useSWR(
        selectedDates.startDate && selectedDates.endDate
            ? [
                `${restaurantId}`,
                selectedDates.startDate,
                selectedDates.endDate,
                "summarizedDailyData",
			  ]
            : null,
        ([ id, start, end ]) => RestaurantsDailyAPI.getRestaurantDailyDataSummary({
            restaurantId: +id,
            start,
            end,
        }),
        {
            revalidateOnFocus: false,
            revalidateIfStale: false,
        }
    );

    return {
        restaurantDailyReportsDataLoading:
			restaurantDailyReportsDataLoading && !restaurantDailyReportsDataError,
        restaurantDailyReportsData: restaurantDailyReportsData?.data,
        selectedDates,
    };
}

// const { id } = useRestaurantValidation(restaurantId);
//Summarize data between dates
// const [selectedDates, setSelectedDates] = useState<{
// 	start: Date;
// 	end: Date;
// }>({ start: startDate ?? dates.yesterday, end: endDate ?? dayjs().toDate() });

// const updateStartDate = useCallback(
// 	(start: Date) => {
// 		setSelectedDates({ ...selectedDates, start });
// 	},
// 	[selectedDates]
// );

// const updateEndDate = useCallback(
// 	(end: Date) => {
// 		setSelectedDates({ ...selectedDates, end });
// 	},
// 	[selectedDates]
// );

// return { updateEndDate,
// 	 updateStartDate,}
