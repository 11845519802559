import { Box, Heading } from '@chakra-ui/react'
import React from 'react'
import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component'
import { InfoBox } from '../ui-components/infoBox.component'
import { useLocalization } from '../../hooks/useLocalization.hook'

type Props = {
    averagePerGuest: number
}

const AveragePerGuest = (props: Props) => {
    const { t } = useLocalization();
    return (
        <Box w='full'>
            <Heading mb={4} size={"md"}>{t('incomePageKeys.income_guestAverage')}</Heading>
            <InfoBoxGrid w='full'>
                <InfoBox boldText={!isNaN(props.averagePerGuest) ? props.averagePerGuest.toFixed(2) + '₪' : t('errors.noData')} />
            </InfoBoxGrid>
        </Box>
    )
}

export default AveragePerGuest