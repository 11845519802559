import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import CustomSWRConfig from "./components/customSwrConfig.component";
import AppRouter from "./components/layout/appRouter.component";
import Pagelayout from "./components/layout/pageLayout.component";
import { GlobalDateContextProvider } from "./contexts/DateContext";
import i18n from "./i18n";
import Fonts from "./styles/fonts";
import "./styles/index.css";
import { theme } from "./styles/theme";

function App() {
    const { i18n: i18nHook } = useTranslation();


    return (
        <BrowserRouter>
            <ChakraProvider theme={theme(i18nHook.dir(i18nHook.language))}>
                <Fonts />
                <I18nextProvider i18n={i18n}>
                    <CustomSWRConfig>
                        <GlobalDateContextProvider>
                            <Pagelayout>
                                <AppRouter />
                            </Pagelayout>
                        </GlobalDateContextProvider>
                    </CustomSWRConfig>
                </I18nextProvider>
            </ChakraProvider>
        </BrowserRouter>
    );
}

export default App;
