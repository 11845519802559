import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component'
import { InfoBox } from '../ui-components/infoBox.component'
import { useLocalization } from '../../hooks/useLocalization.hook'
import { Box, Heading } from '@chakra-ui/react'

type NewType = {
    yesterdayAmount: number
    startOfMonthAmount: number
}

type Props = NewType

const GuestAmount = ({ yesterdayAmount, startOfMonthAmount }: Props) => {
    const { t } = useLocalization();
    return (
        <Box>
            <Heading mb={4} size={'md'}>{t('mainPageKeys.guests_amount')}</Heading>
            <InfoBoxGrid w='100%'>
                <InfoBox direction='column' normalText={t('dateAndTimeKeys.yesterday')} boldText={yesterdayAmount ? yesterdayAmount.toString() : "0"}></InfoBox>
                <InfoBox direction='column' normalText={t('dateAndTimeKeys.from_month_start')} boldText={startOfMonthAmount ? startOfMonthAmount.toString() : t('errors.noData')}></InfoBox>
            </InfoBoxGrid>
        </Box>
    )
}

export default GuestAmount