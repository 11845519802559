import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useToasts } from "./useToasts.hook";
import { AuthApi } from "../api/auth.api";
import { MeResponse } from "../api/response.types";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, RequestResponseType } from "../utils/api.util";

export enum AuthState {
    GUEST = "guest",
    SHIFT_MANAGER = "shift_manager",
    ADMIN = "admin",
    LOADING = "loading",
}

export default function useAuth() {
    const { data: me, isLoading, error, mutate } = useSWR("/me", AuthApi.me, {
        revalidateOnFocus: false
    } );
    const navigate = useNavigate();
    const meLoading = useMemo(() => !error && isLoading, [ error, isLoading ]);
    const {errorToast} = useToasts();

    const authStates = useMemo(() => {
        if (isLoading) { return [AuthState.LOADING]; }
        return me?.data.roles ?? [AuthState.GUEST];
    }, [ me?.data.roles, isLoading, error ]);

    const navUser = (mutatedMe?:RequestResponseType<MeResponse>) => {
        const modifiedMe = mutatedMe ?? me;
        if (!modifiedMe?.data.restaurants) { return navigate("/restaurants"); }
        if (modifiedMe?.data.roles.includes(AuthState.ADMIN)) { return navigate(`/restaurants/${modifiedMe?.data.restaurants[0]}`); }
        return navigate(`/restaurants/${modifiedMe?.data.restaurants[0]}/shiftreport`);
    };

    const login = async (email:string, password:string) => {
        if (!email || !password) { return; }
        try {
            await AuthApi.login({email, password});
            mutate().then(navUser);
        } catch (e:any) {
            if (e.response.status === 401) { errorToast("errors.bad_credentials"); } else { errorToast("errors.internal_error"); }
        }

    };


    const internalLogout = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        mutate(undefined);
        navigate("/");
    };


    const logout = async () => {
        try {
            await AuthApi.logout();
            internalLogout();
        } catch (e) {
            errorToast("errors.internal_error");
        }
    };

    return {
        me: me?.data,
        meLoading,
        authStates,
        navUser,
        login,
        logout,
    };
}
