import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import useRestaurantDailyData from "./useRestaurantDailyData.hook";
import useRestaurantValidation from "./useRestaurantValidation.hook";
import { useToasts } from "./useToasts.hook";
import { EmployeeShiftAPI } from "../api/employeeShift.api";
import { EmployeeShift } from "../api/models.api";


export default function useShifts(restaurantsDailyId: number) {

    const {data: employeeShiftData, isLoading: isEmployeeShiftDataLoading, error: employeeShiftDataError} =
     useSWR( restaurantsDailyId ? [ EmployeeShiftAPI.employeeShiftKey, restaurantsDailyId ] : null, ([ _key, restaurantsDailyId ]) => EmployeeShiftAPI.getShiftEmployees(restaurantsDailyId));

    const modifyShiftDataFormActions = useForm<EmployeeShift | EmployeeShift[]>();
    const {errorToast, successToast} = useToasts();
    const {id} = useRestaurantValidation();
    const {revalidateDailyData} = useRestaurantDailyData(+id!);

    //Array and a form hook to create new employee shifts and assign employees to it
    const formActions = useForm<EmployeeShift[]>();

    const [ employeeIds, setEmployeeIds ] = useState<number[]>([]);

    //Save all shifts to the db
    const saveShifts = useCallback(async () => {
        if (!employeeIds.length) { return; }
        const shiftsToCreate = employeeIds.map((id) => {
            return {
                employee_id: id,
                shift_id: restaurantsDailyId
            };
        });
        try {
            await EmployeeShiftAPI.save(shiftsToCreate);
            await mutate([ EmployeeShiftAPI.employeeShiftKey, restaurantsDailyId ], shiftsToCreate);
            formActions.reset();
            successToast("successFeedbacks.data_success");
        } catch (e: unknown) {
            errorToast("errors.internal_error");
        }
    }, [ employeeIds, EmployeeShiftAPI.save ]);

    //Delete from db 
    const deleteEmployeeFromShift = useCallback(async (id:number) => {
        try {
            await EmployeeShiftAPI.deleteById(id);
            await mutate([ EmployeeShiftAPI.employeeShiftKey, restaurantsDailyId ]);
            await revalidateDailyData();
        } catch (e:unknown) {
            errorToast("errors.internal_error");
        }
    }, [ mutate, revalidateDailyData ]);

    //Patch the modified employee shift data and save in the db
    const patchShiftEmployeesData = async (mode: "single" | "many") => {
        let sentData;
        if (mode === "single") {
            sentData = [modifyShiftDataFormActions.getValues()] as EmployeeShift[];
        }
        try {
            const result = await EmployeeShiftAPI.patchEmployeesShiftData(restaurantsDailyId, sentData ?? modifyShiftDataFormActions.getValues() as EmployeeShift[]);
            if (result?.data) {
                await mutate([ EmployeeShiftAPI.employeeShiftKey, restaurantsDailyId ]);
                await revalidateDailyData();
                modifyShiftDataFormActions.reset();
            }
        } catch (e:unknown) {
            errorToast("errors.internal_error");
        }
    };


    return {
        setEmployeeIds,
        saveShifts,
        formActions,
        employeeShiftData,
        isEmployeeShiftDataLoading,
        employeeShiftDataError,
        modifyShiftDataFormActions,
        patchShiftEmployeesData,
        deleteEmployeeFromShift
    };

}