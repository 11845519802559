import React from 'react';
import { Box, BoxProps, Spinner, Text } from '@chakra-ui/react';

export interface InnerBoxProps extends BoxProps {
    icon?: JSX.Element | string;
    normalText?: string,
    boldText?: string | JSX.Element;
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    isLoading?: boolean;
    iconClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    containerClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    children?: JSX.Element;

}

export const InfoBox = (props: InnerBoxProps) => {
    const { icon, normalText, direction, boldText, isLoading, containerClick, iconClick, children, ...boxProps } = props;
    const isColumn = direction?.includes('column');
    const internalIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        iconClick && iconClick(e)
    }

    return (
        <Box
            display={'flex'}
            background={'white'}
            boxShadow="md"
            borderRadius={'sm'}
            p={'20px'}
            width={'100%'}
            height={isColumn ? '80px' : '54px'}
            justifyContent={'space-between'}
            alignItems={'center'}
            onClick={containerClick}
            _hover={containerClick && { cursor: 'pointer' }}
            {...boxProps}
        >
            <Box
                flexGrow={isColumn ? 0 : 1}
                display={'flex'}
                flexDir={direction || 'row'}
                alignItems={isColumn ? 'flex-start' : 'space-between'}
                justifyContent={isColumn ? 'normal' : 'space-between'}
                gap={'7px'}
            >
                {
                    isLoading ?
                        <>
                            <Spinner />
                        </>
                        :
                        <>
                            {children && children}
                            {normalText && <Text lineHeight={1} fontSize="sm" color="primary" fontWeight={'normal'} textColor={'systemTextHeader'}>{normalText}</Text>}
                            {boldText && <Text lineHeight={1} fontSize="md" color="black" fontWeight={'bold'}>{boldText}</Text>}
                        </>
                }
            </Box>
            {icon &&
                <Box _hover={iconClick && { cursor: 'pointer' }} onClick={internalIconClick} ps={'20px'}>
                    {icon}
                </Box>
            }
        </Box>
    );
};