import { useState } from "react";
import {
    Spinner,
    TableContainer,
    Toast,
    Table,
    Thead,
    Th,
    Tr,
    Tbody,
    Td,
    Box,
    Heading,
    Button,
    useMediaQuery,
    useDisclosure,
} from "@chakra-ui/react";
import EditEmployeeModal from "./editEmployeeModal.component";
import { EmployeeResponse } from "../../api/response.types";
import useEmployees from "../../hooks/useEmployees.hook";
import { useLocalization } from "../../hooks/useLocalization.hook";
import breakpoints from "../../styles/breakpoints";
import { formatNumber } from "../../utils/formatNumber.util";

type Props = {
    restaurantId: number
}


const EmployeesList = ({ restaurantId }: Props) => {
    const { deleteEmployee, employees, employeesError, isEmployeesLoading } = useEmployees(restaurantId);
    const { t } = useLocalization();
    const [isMobile] = useMediaQuery(breakpoints.isMobile);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ editEmployee, setEditEmployee ] = useState<EmployeeResponse>();

    if (isEmployeesLoading) { return <Spinner />; }
    if (employeesError) { return <></>; }

    const editButtonClick = (emp: EmployeeResponse) => {
        setEditEmployee(emp);
        onOpen();
    };

    return (
        <>
            <Box p={[ 0, 10 ]} w={isMobile ? "90vw" : ""}>
                <Heading textAlign={[ "center", "unset" ]}>{t("tabNames.employees_tab")}</Heading>
                <Box overflow={"scroll"} maxW={"95%"} maxH={"60vh"} >
                    <TableContainer pr={2} mt={5}>
                        <Table variant={"striped"} colorScheme="linkedin" size={"sm"}>
                            <Thead>
                                <Tr>
                                    <Th>
                                        {t("employeeAttributesKeys.name")}
                                    </Th>
                                    <Th>
                                        {t("employeeAttributesKeys.department")}
                                    </Th>
                                    <Th>
                                        {t("employeeAttributesKeys.salary_type")}
                                    </Th>
                                    <Th>
                                        {t("employeeAttributesKeys.salary_time_frame")}
                                    </Th>
                                    <Th>
                                        {t("employeeAttributesKeys.salary")}
                                    </Th>
                                    <Th>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {employees?.map((emp, index) => <Tr key={index}>
                                    <Td>{emp.name}</Td>
                                    <Td>{emp.department ? t(`departments.${emp.department?.toUpperCase()}`): "-"}</Td>
                                    <Td>{t(`salaryTypes.${emp.salary_type?.toUpperCase()}`)}</Td>
                                    <Td>{t(`salaryTimeFrame.${emp.salary_time_frame?.toUpperCase()}`)}</Td>
                                    <Td>{formatNumber({ number: +emp.salary, t })}</Td>
                                    <Td><Button
                                        size="sm"
                                        _hover={{ cursor: "pointer" }}
                                        onClick={() => editButtonClick(emp)}
                                    >{t("edit")}
                                        </Button>
                                    </Td>
                                    <Td><Button
                                        size="sm"
                                        _hover={{ cursor: "pointer" }}
                                        onClick={() => deleteEmployee(emp.id, index)}
                                    >{t("delete")}
                                        </Button>
                                    </Td>
                                                                </Tr>)}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            {isOpen && <EditEmployeeModal isOpen={isOpen} onClose={onClose} restaurantId={restaurantId} employee={editEmployee!} />}
        </>
    );
};

export default EmployeesList;