import { AuthState } from "../hooks/useAuth.hook";
import { EmployeeResponse } from "./response.types";

export type languages = 'he' | 'en';

export interface User {
    fname:string,
    lname:string,
    email:string,
    roles:AuthState[]
}

export interface Restaurant {
    name:string,
    provider_restaurant_id:string,
    provider_branch_id:string,
    integration_provider:string,
    income_goal:number
    fixed_costs:number
    flexible_costs:number,
    unexpected_costs:number,
    work_days:number
}


export interface DailyAnalytics {
    dailyIncome:number,
    guestAmount:number,
    creditIncome:number,
    tipTotal:number,
    deliveryIncome: number
    deliveryCount: number
    totalDiscount: number
    totalRefund: number
    cashIncome: number
    totalDishesExpenses: number
}

export interface DailyWithIdAndAnalytics extends RestaurantsDaily {
    id: number,
    shiftIncomes:number,
    shiftExpenses:number,
}
export interface RestaurantsDaily {
   analytics: DailyAnalytics;
   date:Date,
   description?: string;
   restaurant_id:number,
   totalTip:number,
   cashTip:number,
   completions:number
}

export enum SalaryTypes {
    CASH = "cash",
    CREDIT = "credit"
}
export enum Departments {
    COOK = 'cook',
    WAITER = 'waiter',
    SHIFT_MANAGER ='shift_manager',
    RUNNER = 'runner',
    BARMEN = 'barmen',
    OFFICE_MANAGER = 'office_manager',
    WAITERS_MANAGER = 'waiters_manager',
    BAR_MANAGER = 'bar_manager',
    FLOOR_MANAGER = 'floor_manager',
    BACK_MANAGER = 'back_manager',
    PLACE_MANAGER = 'place_manager',
    HOST = 'host',
    HOSTS_MANAGER = 'hosts_manager',
    PHONE_OPERATOR = 'phone_operator'
}

export enum SalaryTimeFrame {
    MONTHLY = 'monthly',
    HOURLY = 'hourly',
    PER_SHIFT = 'per_shift'
}

export interface Employee {
    name: string
    salary :string
    department?:Departments
    salary_type : SalaryTypes
    salary_time_frame: SalaryTimeFrame
    restaurant_id:number
}

export interface EmployeeShift {
    employee_id: number;
    shift_id: number;
    hours_amount: number;
    tips: number;
    bonuses: number;
}

export interface EmployeeShiftWithEmployeeData extends EmployeeShift {
   employee: Partial<EmployeeResponse> & {id:number};
}


export type TotalTip<T = string | number> = {
    cashTip: T,
    completions: T,
    creditTip: T;
  }



export interface Dish {
    provider_id:number
    restaurant_id: number
    dish_name:string
    categories: string[]
    expenses?: number
    calculation_type : 'relational' | 'literal'
    incomes?:number
}