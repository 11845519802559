import React from "react";
import { Box, HStack, Input, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

interface IDatePicker {
	selectedDate: Date;
	updateSelectedDate: (date: Date) => void;
}

const DatePicker = React.memo(
    ({ selectedDate, updateSelectedDate }: IDatePicker) => {
        return (
            <Box display={"flex"} gap={10}>
                <HStack width={"fit-content"}>
                    <Text color={'white'} whiteSpace={"nowrap"}>{t("date")}</Text>
                    <Input
                        fontSize={"1rem"}
                        type="date"
                        bg={"white"}
                        textColor={"black"}
                        max={dayjs().format("YYYY-MM-DD")}
                        onChange={(e) => {
                            updateSelectedDate(new Date(e.target.value));
                        }}
                        value={dayjs(selectedDate).format("YYYY-MM-DD")}
                    />
                </HStack>
            </Box>
        );
    }
);

export default DatePicker;
