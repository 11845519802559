import { AuthState } from "../../../hooks/useAuth.hook";

export interface NavItemInfo {
	route: string;
	text: string;
	authStates?: AuthState[];
	icon: string;
}

export const navs: NavItemInfo[] = [
	{
		route: "/restaurants/:id",
		text: "pageName.main",
		icon: "/svg/home.svg",
		authStates: [AuthState.ADMIN],
	},
	{
		route: "/login",
		text: "pageName.login",
		authStates: [AuthState.GUEST],
		icon: "/svg/cashRegister.svg",
	},
	{
		route: "/restaurants/:id/incomes",
		text: "pageName.incomes",
		authStates: [AuthState.ADMIN],
		icon: "/svg/cashRegister.svg",
	},
	{
		route: "/restaurants/:id/expenses",
		text: "pageName.expenses",
		authStates: [AuthState.ADMIN],
		icon: "/svg/expenses.svg",
	},
	{
		route: "/restaurants/:id/shiftreport",
		text: "pageName.shiftReport",
		authStates: [AuthState.ADMIN, AuthState.SHIFT_MANAGER],
		icon: "/svg/reports.svg",
	},
	{
		route: "/restaurants/:id/settings",
		text: "pageName.settings",
		authStates: [AuthState.ADMIN, AuthState.SHIFT_MANAGER],
		icon: "/svg/settings.svg",
	},
];
