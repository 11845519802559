import { Box, FormErrorMessage, FormLabel, Input, InputProps } from '@chakra-ui/react'
import React from 'react'
import { Path, UseFormReturn } from 'react-hook-form'

interface FormItemProps<T> extends InputProps {
    name: Path<T>
    displayName: string
    formActions?: UseFormReturn<any>
    validation?: any
    children?: JSX.Element
}

function FormItem<T>({ displayName, formActions, name, validation, children, ...props }: FormItemProps<T>) {
    return (
        <Box>
            <FormLabel>{displayName}</FormLabel>
            <Input {...formActions?.register(name, { ...validation })} {...props} />
            {children}
        </Box>)
}

export default FormItem