import React, { useMemo } from "react";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { matchPath } from "react-router-dom";
import RestaurantBanner from "./restuarantBanner.component";
import MobileSideBar from "./sidebar/mobileSideBar.component";
import Sidebar from "./sidebar/sidebar.component";
import useAuth, { AuthState } from "../../hooks/useAuth.hook";
import { useLocalization } from "../../hooks/useLocalization.hook";
import breakpoints from "../../styles/breakpoints";
import { useGlobalDateContext } from "../../contexts/DateContext";
import DatePicker from "../ui-components/datePicker.component";

interface PagelayoutProps {
	children: JSX.Element;
}

export default function Pagelayout(props: PagelayoutProps) {
	const [isMobile] = useMediaQuery(breakpoints.isMobile);
	const { authStates, me } = useAuth();
	const { direction } = useLocalization();
	const { selectedDate, updateSelectedDate } = useGlobalDateContext();

	const fixedLayout = direction === "rtl" ? { mr: "315px" } : { ml: "315px" };
	const id = useMemo(() => {
		const match = matchPath({ path: "/restaurants/:id/*" }, location.pathname);
		return match?.params.id ?? "";
	}, [location.pathname]);
	if (isMobile) {
		return (
			<Box
				display={"flex"}
				flexDir={"column"}
				alignItems={"center"}
				minH={"100vh"}
				overflow={"hidden"}
			>
				<RestaurantBanner me={me} />
				<Box
					width={"100%"}
					display={"flex"}
					justifyContent={"start"}
					p={[5, null, null, 0]}
				>
					{authStates.includes(AuthState.ADMIN) && (
						<DatePicker
							selectedDate={selectedDate}
							updateSelectedDate={updateSelectedDate}
						/>
					)}
				</Box>
				<Box pb={"10vh"}>{props.children}</Box>
				<MobileSideBar id={id} />
			</Box>
		);
	}

	return (
		<Box
			display={"flex"}
			flexDir={"row-reverse"}
			h={"100%"}
			overflowY={"auto"}
			w={"100vw"}
		>
			<Box {...fixedLayout} px={"2vw"} flexGrow={1} py={"2vh"}>
				{props.children}
			</Box>
			<Sidebar />
		</Box>
	);
}
