import React, { useMemo } from "react";
import { Box, Heading, Link } from "@chakra-ui/react";
import { Link as ReactLink, useLocation } from "react-router-dom";
import { RestaurantResponse } from "../../api/response.types";
import { useLocalization } from "../../hooks/useLocalization.hook";
import { formatNumber } from "../../utils/formatNumber.util";
import { InfoBox } from "../ui-components/infoBox.component";
import { InfoBoxGrid } from "../ui-components/InfoBoxGrid.component";

type Props = {
	yesterdayData: number;
	startOfMonthData: number;
	restaurant: RestaurantResponse;
	showLink?: boolean;
};

const TotalIncomes = ({
    startOfMonthData,
    yesterdayData,
    restaurant,
    showLink,
}: Props) => {
	
    const { t } = useLocalization();
    const location = useLocation();

    const percentage = useMemo(() => {
        return (startOfMonthData! / restaurant?.income_goal!) * 100;
    }, [ startOfMonthData, restaurant?.income_goal ]);

    const shownPercentage =
		isNaN(percentage) || !restaurant.income_goal ? t("errors.noData") : `${percentage.toFixed(1)}%`;

    return (
        <>
            <Box mt={[ -2, 10 ]}>
                <Box
                    w={"100%"}
                    display={"flex"}
                    flexDir={"row"}
                    justifyContent={"space-between"}
                >
                    <Heading mb={4} size={"md"}>
                        {t("incomePageKeys.income_total")}
                    </Heading>
                    {showLink && (
                        <Link
                            variant="secondary-title"
                            as={ReactLink}
                            to={`${location.pathname}/incomes`}
                        >
                            {`${t("routingKeys.toAllIncomes")} >`}
                        </Link>
                    )}
                </Box>
                <InfoBoxGrid w={"100%"}>
                    <InfoBox
                        direction="column"
                        // normalText={t("dateAndTimeKeys.yesterday")}
                        boldText={formatNumber({ number: yesterdayData, t })}
                    />
                    <InfoBox
                        direction="column"
                        normalText={t("dateAndTimeKeys.from_month_start")}
                        boldText={formatNumber({ number: startOfMonthData, t })}
                    />
                    <InfoBox
                        direction="column"
                        normalText={t("incomePageKeys.destination_percentage")}
                        boldText={shownPercentage}
                    />
                </InfoBoxGrid>
            </Box>
        </>
    );
};

export default TotalIncomes;
