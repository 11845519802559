import { useMemo } from 'react';
import { MeResponse } from '../../api/response.types'
import { Box } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg'
import { InfoBox } from '../ui-components/infoBox.component'
import UserRestaurants from './userRestaurantsSelection.component'

interface Props {
    me?: MeResponse
}

function RestaurantBanner({ me }: Props) {

    const isShowingRestaurantSelection = useMemo(() => {
        return (location.pathname !== '/') && (location.pathname !== '/login')
    }, [location.pathname])


    if (!me || !isShowingRestaurantSelection) return <></>;
    return (
        <Box w={['100%', '90%', '80%', '100%', '']} p={[5, null, null, 0]} >
            <InfoBox height={'90px'} direction="column-reverse" boldText={`${me?.fname} ${me?.lname}`} icon={<ReactSVG src={'/svg/profile.svg'} />}>
                {isShowingRestaurantSelection ? <UserRestaurants /> : undefined}
            </InfoBox>
        </Box>
    )

}

export default RestaurantBanner