import React, { useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    FormControl,
    Box,
} from '@chakra-ui/react'
import Item from '../ui-components/formItem.component';
import { EmployeeShift } from '../../api/models.api';
import { useLocalization } from '../../hooks/useLocalization.hook';
import useShifts from '../../hooks/useShifts.hook';
import { EmployeeShiftWithEmployeesResponse } from '../../api/response.types';

type Props = {
    employeeShiftData: EmployeeShiftWithEmployeesResponse
    isOpen: boolean
    restaurantDailyId: number
    onClose: () => void
}

function EmployeeShiftModal({ employeeShiftData, isOpen, onClose, restaurantDailyId }: Props) {
    const { t } = useLocalization();
    const { modifyShiftDataFormActions, patchShiftEmployeesData } = useShifts(restaurantDailyId);

    useEffect(() => {
        modifyShiftDataFormActions.setValue("bonuses", employeeShiftData.bonuses);
        modifyShiftDataFormActions.setValue("hours_amount", employeeShiftData.hours_amount);
        modifyShiftDataFormActions.setValue("tips", employeeShiftData.tips);
    }, [])

    const handleSubmit = () => {
        if (!employeeShiftData) return;
        modifyShiftDataFormActions.setValue("employee_id", employeeShiftData.employee.id);
        patchShiftEmployeesData("single");
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent dir='rtl' as='form' onSubmit={modifyShiftDataFormActions.handleSubmit(handleSubmit)}>
                <ModalHeader>{employeeShiftData?.employee.name}</ModalHeader>
                <ModalBody>
                    <FormControl>
                        <Box>
                            <Item<EmployeeShift> validation={{ required: true }} name={'hours_amount'} displayName={t('shiftReportKeys.employeeHours')} formActions={modifyShiftDataFormActions} />
                            <Item<EmployeeShift> validation={{ required: true }} name={'tips'} displayName={t('shiftReportKeys.tips')} formActions={modifyShiftDataFormActions} />
                            <Item<EmployeeShift> validation={{ required: true }} name={'bonuses'} displayName={t('shiftReportKeys.bonuses')} formActions={modifyShiftDataFormActions} />
                        </Box>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button type='submit'>
                        {t('save')}
                    </Button>
                    <Button mr={3} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default EmployeeShiftModal