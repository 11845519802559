import { TableContainer, Box, Table, Thead, Tbody, Td, Th, Tr, Badge, Tag, TagLabel, Avatar, Button, Spinner } from '@chakra-ui/react'
import React from 'react'
import { useLocalization } from '../../hooks/useLocalization.hook';
import { EmployeeShiftWithEmployeesResponse } from '../../api/response.types';

type Props = {
    data?: EmployeeShiftWithEmployeesResponse[]
    handleEmployeeClick: (emp: EmployeeShiftWithEmployeesResponse) => void
    handleDelete: (id: number) => void;
}

function ShiftEmployeesTable({ data, handleEmployeeClick, handleDelete }: Props) {
    const { t, direction } = useLocalization();
    const tagAnchor = direction === 'rtl' ? { ml: 1 } : { mr: 1 };
    if (!data) return <Spinner />;

    return (
        <Box overflow={"scroll"} maxW={'90vw'} maxH={'40vh'} >
            <TableContainer>
                <Table variant="simple" colorScheme='linkedin'>
                    <Thead>
                        <Tr>
                            <Th>{t('employeeAttributesKeys.name')}</Th>
                            <Th>{t('employeeAttributesKeys.department')}</Th>
                            <Th>{t('shiftReportKeys.employeeHours')}</Th>
                            <Th>{t('shiftReportKeys.tips')}</Th>
                            <Th>{t('shiftReportKeys.bonuses')}</Th>
                            <Th></Th>
                            <Th></Th>

                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.map((emp) => {
                            return <Tr key={emp.id}>
                                <Td><Tag>
                                    <Avatar size='xs' name={emp.employee.name} {...tagAnchor} />
                                    <TagLabel>{emp.employee.name}</TagLabel>
                                </Tag></Td>
                                <Td><Badge colorScheme={emp.employee.deleted_at ? 'red' : 'gray'} px={2}>{emp.employee.deleted_at ? t('deleted') : t(emp.employee.department ? `departments.${emp.employee.department?.toUpperCase()}` : 'empty')}</Badge></Td>
                                <Td>{emp.hours_amount?.toFixed(2) ?? "-"}</Td>
                                <Td>{emp.tips ? emp.tips + " ₪" : "-"}</Td>
                                <Td>{emp.bonuses ? emp.bonuses + " ₪" : "-"}</Td>
                                <Td><Button isDisabled={Boolean(emp.employee.deleted_at!)} aria-label='Add-employee' size={'sm'} onClick={() => handleEmployeeClick(emp)}>{t('edit')}</Button></Td>
                                <Td><Button aria-label='Remove-employee' size={'sm'} onClick={() => handleDelete(emp.id)}>{t('delete')}</Button></Td>

                            </Tr>
                        })}

                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default ShiftEmployeesTable