import { Chart as ChartJs, Legend, Tooltip, ArcElement } from 'chart.js'
import React, { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component';
import { ReactSVG } from 'react-svg';
import { Box, Flex, Heading, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { InfoBox } from '../ui-components/infoBox.component';
import { useLocalization } from '../../hooks/useLocalization.hook';
import { formatNumber } from '../../utils/formatNumber.util';
import breakpoints from '../../styles/breakpoints';

ChartJs.register(
    ArcElement,
    Tooltip,
    Legend
)

type Props = {
    total: number
    actual: number
}

function Gauge({ actual, total }: Props) {
    const { t } = useLocalization();
    const [isMobile] = useMediaQuery(breakpoints.isMobile);
    const data = {

        datasets: [{
            data: [
                1 * 0.3,
                1 * 0.4,
                1 * 0.3,
            ],
            backgroundColor: [
                '#FF825A',
                '#FFE074',
                '#69F476'
            ],
            borderColor: '#14B7D3',
            borderWidth: 10,
            circumference: 180,
            rotation: 270,
            cutout: '85%',
            maintainAspectRatio: false,
            responsive: true,
        }]
    }

    /*
        The initial meter SVG is rotates by 135deg.
        Calculating the income percentage and rotating by the realDegree - the initial (135deg)
    */
    const INITIAL_DEGREE = 135
    const realDegree = useMemo(() => actual <= total ? ((actual / total) * 180) : 180, [actual, total]);
    const percentage = useMemo(() => (actual / total) * 100, [actual, total]);

    return (
        <Box>
            <Heading mb={3} size="md">{`${t('incomePageKeys.income_total')} ${t('dateAndTimeKeys.from_month_start')}`}</Heading>
            <Box display={'grid'} placeItems={'center'} h='full'>
                <Box>
                    <InfoBoxGrid display='flex' maxW={isMobile ? '95vw' : ''} w={['320p', '380px', '95vw', '550px']} h={['320px', '375px', '400px', '450px']} overflow={'hidden'} justifyContent={'center'}>
                        <Box w={['320px', '380px', '460px', '550px']} display={'flex'} flexDir={'column'}>
                            <InfoBox alignSelf={'center'} w={'90%'} direction='column' boldText={formatNumber({ number: total, allowZero: false, t })} normalText={t('destination')} />
                            <Box position={'relative'}>
                                <Stack>
                                    <Doughnut data={data} options={{ plugins: { legend: { display: false }, tooltip: { enabled: false } } }} />
                                </Stack>
                                <Box position={'absolute'} top={['35%', '36%', '38%', '40%']} left={''} w='full' display={'flex'} justifyContent={'center'}>
                                    <Box position={'relative'} w={'200px'} height={'200px'} style={{ transform: `rotate(${realDegree - INITIAL_DEGREE}deg)` }} justifyContent={'center'}>
                                        <ReactSVG style={{ position: 'absolute', left: '50%', bottom: '50%', transform: 'scale(1.4)' }} src='/svg/meter.svg' />
                                        <ReactSVG src='/svg/circle.svg' style={{ transform: 'scale(1.25)', position: 'absolute', bottom: 'calc(50% - 17px)', left: 'calc(50% - 17px)' }} />
                                    </Box>
                                    <Text fontSize={['sm', 'md']} color={'white'} style={{ position: 'absolute', bottom: 'calc(50% - 14px)', left: 'calc(50% - 13px)' }}>{isNaN(percentage) || !total ? '' : percentage.toFixed(0) + '%'}</Text>
                                </Box>
                            </Box>
                            <Box zIndex={2} position={'relative'} right={'20px'} bottom={'480px'}>
                                <ReactSVG src='/svg/background_line.svg' style={{ transform: 'scale(1.4)' }} />
                            </Box>
                        </Box>
                    </InfoBoxGrid >
                    <Box position={'relative'} left={3} bottom={9}>
                        <ReactSVG src='/svg/coins.svg' />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Gauge