import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    FormControl,
    Stack,
} from '@chakra-ui/react';
import { Restaurant } from '../api/models.api';
import { useLocalization } from '../hooks/useLocalization.hook';
import FormItem from './ui-components/formItem.component';

type Props = {
    isOpen: boolean
    onClose: () => void
}

type CreateRestaurantRequest = Partial<Restaurant>;

function AddRestaurant({ isOpen, onClose }: Props) {
    const { t } = useLocalization();

    return (
        <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
            <ModalOverlay />
            <ModalContent dir='rtl' textAlign={'center'} as='form'>
                <ModalHeader>{`${t('link')} ${t('restaurantsPageKeys.new_restaurant')}`}</ModalHeader>
                <ModalBody>
                    <FormControl>
                        <Stack spacing={4}>
                            <FormItem<CreateRestaurantRequest> name="name" displayName={t('employeeAttributesKeys.name')} validation={{ required: true }} />
                        </Stack>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button type='submit'>
                        {t('save')}
                    </Button>
                    <Button mr={3} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default AddRestaurant