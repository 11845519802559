import React, { useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Footer from "./footer.component";
import { navItem } from "./navItem.component";
import CleanProfitBanner from "./profitBanner.component";
import { navs } from "./routes";
import { useGlobalDateContext } from "../../../contexts/DateContext";
import useAuth, { AuthState } from "../../../hooks/useAuth.hook";
import { useLocalization } from "../../../hooks/useLocalization.hook";
import { calcStartOfPickedDateMonth } from "../../../utils/dates.util";
import DatePicker from "../../ui-components/datePicker.component";
import RestaurantBanner from "../restuarantBanner.component";

export default function Sidebar() {
    const { t, direction } = useLocalization();
    const { me, authStates } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {selectedDate, updateSelectedDate} = useGlobalDateContext();

    const dailyProfitText = `${t("incomeKeys.totalCleanProfit")}:`;

    const startOfMonthProfitText = `${t("incomeKeys.totalCleanProfit")} ${t(
        "dateAndTimeKeys.from_month_start"
    )}:`;
    const id = useMemo(() => {
        const match = matchPath({ path: "/restaurants/:id/*" }, location.pathname);
        return match?.params.id;
    }, [location.pathname]);

    const sideBarAnchor = direction === "rtl" ? { right: 0 } : { left: 0 };
    return (
        <Box
            color='white'
            h={"100%"}
            minH={"100vh"}
            overflowY={"auto"}
            bgGradient='linear(165deg, primary,primary,secondary, secondary)'
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            p={"20px"}
            width={"30%"}
            maxW={"315px"}
            boxSizing='border-box'
            position={"fixed"}
            {...sideBarAnchor}
        >
            <Box
                mb={"45px"}
                onClick={() => navigate("/")}
                _hover={{ cursor: "pointer" }}
            >
                <ReactSVG src='/svg/alfred_white_logo.svg' />
            </Box>


            <Box width='100%' flexGrow={1}>
                <RestaurantBanner me={me} />
                {authStates.includes(AuthState.ADMIN) &&
                <div  style={{padding:"8px"}} >

                    <DatePicker
                        selectedDate={selectedDate}
                        updateSelectedDate={updateSelectedDate}
                    />
                </div>
                
                }
                <Text w='full' py={"20px"} opacity={0.75}>
                    {t("menu")}
                </Text>
                <Box display={"flex"} flexDir={"column"} w={"100%"} gap={4}>
                    {navs.map((x, i) => navItem(x, i, authStates, id))}
                </Box>
            </Box>

            {authStates.includes(AuthState.ADMIN) && (
                <>
                    <CleanProfitBanner
                        startDate={selectedDate}
                        endDate={selectedDate}
                        text={dailyProfitText}
                        id={+id!}
                    />
                    <CleanProfitBanner
                        startDate={calcStartOfPickedDateMonth(selectedDate)}
                        endDate={selectedDate}
                        text={startOfMonthProfitText}
                        id={+id!}
                    />
                </>
            )}
            <Footer />
        </Box>
    );
}
