import { Button, Heading, Stack, useDisclosure } from '@chakra-ui/react';
import { useLocalization } from '../hooks/useLocalization.hook';
import useAuth from '../hooks/useAuth.hook';
import AddRestaurant from '../components/addRestaurant.component';

export default function Restaurants() {
    const { t } = useLocalization();
    const { navUser } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Stack spacing={'2vh'} direction={'column'} p={10}>
                <Heading size={"lg"}>{t('pageName.restaurants')}</Heading>
                <Heading size={"md"}>{t('restaurantsPageKeys.no_restaurants')}</Heading>
                <Stack w={'fit-content'} direction={'column'}>
                    <Button isDisabled onClick={onOpen}>{`${t('link')} ${t('restaurantsPageKeys.new_restaurant')}`}</Button>
                    <Button onClick={() => navUser()} variant={'link'}>{t('restaurantsPageKeys.have_restaurants')}</Button>
                </Stack>
            </Stack>
            {isOpen && <AddRestaurant isOpen={isOpen} onClose={onClose} />}
        </>
    );
}