import { DailyAnalytics, DailyWithIdAndAnalytics, RestaurantsDaily } from './models.api';
import { request } from '../utils/api.util';
import { Distributions, RestaurantResponse, RestaurantsDailyResponse } from './response.types';
import { RestaurantDailyRangeRequest } from './request.types';

export class RestaurantsDailyAPI {
    public  static readonly employeeShiftKey = 'restaurantsDailyKey';

    static async loadRestaurantData(body:RestaurantDailyRangeRequest) {
        return request<boolean>({
            uri: 'loadRestaurantData',
            method: 'POST',
            body
        });
    }

    static async getRestaurantDailyDataSummary(body:RestaurantDailyRangeRequest) {
        return request<DailyAnalytics & {expenses:number}>({
            uri: 'restaurantsDaily/summarizedDailyData',
            method: 'POST',
            body
        });
    }

    static async calculateTotalTip(body: {id:number, cashTip:number, completions:number}) {
        return request<number>({
            uri: `restaurantsDaily/${body.id}/calculateTip`,
            method: 'PATCH',
            body
        });
    }

    static async getRestaurantDailyDataAverage(body:RestaurantDailyRangeRequest) {
        return request<DailyAnalytics>({
            uri: 'restaurantsDaily/averageDailyData',
            method: 'POST',
            body
        });
    }

    static async getByDate(id: RestaurantResponse["id"], date: Date) {
        const dateAsString = encodeURIComponent(date.toISOString());
        return request<DailyWithIdAndAnalytics>({
            uri: `restaurantsDaily/byDate/${id}/${dateAsString}`,
            method:'GET'
        })
    }
    static async edit (id: RestaurantsDailyResponse["id"], body: Partial<RestaurantsDaily>) {
        return request<RestaurantsDailyResponse>({
            uri: `restaurantsDaily/${id}`,
            method: 'PUT',
            body
        })
    }
    static async getCleanProfitByDates(body: RestaurantDailyRangeRequest) {
        return request<Distributions<'income'>>({
            uri: 'restaurantsDaily/cleanProfitByDates',
            method: 'POST',
            body
        });
    }

    static async getDishExpensesByDates(body: RestaurantDailyRangeRequest) {
        return request<Distributions<'dishExpenses'>>({
            uri: 'restaurantsDaily/dishExpensesByDate',
            method: 'POST',
            body
        });
    }

    static async getCancelationByDate(body: RestaurantDailyRangeRequest) {
        return request<Distributions<"totalCancel">>({
            uri: 'restaurantsDaily/cancellationsByDate',
            method: 'POST',
            body
        });
    }

    static async getGuestsByDate(body: RestaurantDailyRangeRequest) {
        return request<Distributions<"guests">>({
            uri: 'restaurantsDaily/guestsByDate',
            method: 'POST',
            body
        });
    }

    static async getDiscountByDate(body: RestaurantDailyRangeRequest) {
        return request<Distributions<"discount">>({
            uri: 'restaurantsDaily/refundsByDate',
            method: 'POST',
            body
        });
    }

    static async getLastUpdated(id:number) {
        return request<Date>({
            uri: `restaurantsDaily/${id}/lastUpdated`,
            method: 'GET'
        })
    }

    static getCleanProfit(restaurantId:number, {start, end}: {start:Date, end:Date}) {
        const startDateString = encodeURIComponent(start.toISOString());
        const endDateString = encodeURIComponent(end.toISOString());
        return request<number>({
            uri: `restaurantsDaily/${restaurantId}/cleanProfit?start=${startDateString}&end=${endDateString}`,
            method: "GET",
        });
    }

}