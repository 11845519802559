import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import useAuth from './useAuth.hook';
import { UserAPI } from '../api/user.api';

export default function useUserRestaurants() {
    const {me} = useAuth();
    const {data, isLoading} = useSWR(me ? [ `${me?.id}`, 'getUserRestaurantsList' ] : null, ([id]) => UserAPI.getUserRestaurants(+id) , {
        revalidateOnFocus: false
    });

    const navigate = useNavigate();

    const findById = (id:number) => {
        if(isLoading) return;
        return data?.data.find(x => x.id === id);
    }
    
    const switchRestaurant = (id: number, route:string) => {
        const restaurant = findById(id);
        if(!restaurant) return;
        navigate(route);
    }

    return {
        restaurants: data?.data,
        navigate,
        switchRestaurant,
        findById,
        isLoading
    };
}