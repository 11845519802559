import { EmployeeShift } from "./models.api";
import { EmployeeResponse, EmployeeShiftWithEmployeesResponse } from "./response.types";
import { request } from "../utils/api.util";

export class EmployeeShiftAPI {
    public static readonly employeeShiftKey = "employShiftsKey";
    static async save(body: Array<{employee_id: number, shift_id: number}> ) {
        return request<EmployeeResponse>({
            uri: "employeesShifts/save",
            method: "POST",
            body
        });
    }
    static async getShiftEmployees(shiftId: number) {
        return request<EmployeeShiftWithEmployeesResponse[]>({
            uri: `employeesShifts/${shiftId}/employees`,
            method: "GET",
        });
    }
    static patchEmployeesShiftData(shiftId:number, body: EmployeeShift[]) {
        return request<{status:number, data:boolean}>({
            uri: `employeesShifts/${shiftId}/employees`,
            method: "PATCH",
            body
        });
    }
    static deleteById(employeeShiftId:number) {
        return request<void>({
            uri: `employeesShifts/${employeeShiftId}`,
            method: "DELETE",
        });
    }
    static getEmployeesCosts(restaurantId:number, {start, end}: {start:Date, end:Date}) {
        const startDateString = encodeURIComponent(start.toISOString());
        const endDateString = encodeURIComponent(end.toISOString());

        return request<number>({
            uri: `employeesShifts/${restaurantId}/employeesCosts?start=${startDateString}&end=${endDateString}`,
            method: "GET",
        });
    }
}