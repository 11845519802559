import { Box, Button, Flex, Heading, Input } from '@chakra-ui/react';
import { useLocalization } from '../../hooks/useLocalization.hook';
import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component';
import { InfoBox } from '../ui-components/infoBox.component';
import { formatNumber } from '../../utils/formatNumber.util';
import { UseFormReturn } from 'react-hook-form';
import { TotalTip } from '../../api/models.api';

type Props = {
  formActions: UseFormReturn<TotalTip<number>, any, undefined>
  calculateTip: () => Promise<void>
  totalTip: number
  tipCredit: number
}

function ShiftTips({ calculateTip, formActions, tipCredit, totalTip }: Props) {
  const { t } = useLocalization();
  return (
    <Box as="form" onSubmit={formActions.handleSubmit(calculateTip)}>
      <Heading size={"md"} mb={3}>{t('tipsKeys.title')}</Heading>
      <InfoBoxGrid w={"100%"}>

        <InfoBox direction='column-reverse' normalText={t('tipsKeys.tipInCash')}>
          <Flex alignItems={'center'}>
            <Input w={'100px'} {...formActions.register('cashTip', { min: 0 })} type='number' inputMode='numeric' border={"none"} fontWeight={"bold"} placeholder={t('typeAmountInput')} />
            ₪
          </Flex>
        </InfoBox>
        <InfoBox direction='column-reverse' normalText={t('tipsKeys.completions')}>
          <Flex alignItems={'center'}>
            <Input w={'100px'} {...formActions.register("completions", { min: 0 })} type='number' inputMode='numeric' border="none" fontWeight={"bold"} placeholder={t('typeAmountInput')} />
            ₪
          </Flex>
        </InfoBox>
        <InfoBox direction='column' normalText={t('tipsKeys.tipInCredit')} boldText={formatNumber({ number: tipCredit, t })} />
        <InfoBox direction='column' normalText={t('tipsKeys.totalTip')} boldText={formatNumber({ number: totalTip, t, allowZero: true })} />
      </InfoBoxGrid>
      <Flex w='full' justifyContent={'end'}>
        <Button isLoading={formActions.formState.isSubmitting} mt={4} mx={1} w={['full', 'fit-content']} type='submit'>{t('calculate')}</Button>
      </Flex>

    </Box>
  )
}

export default ShiftTips
