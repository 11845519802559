import { EmployeeResponse } from './response.types';
import { request } from '../utils/api.util';
import { Employee } from './models.api';

export class EmployeesAPI {
    public  static readonly employeeKey = 'employeeKey'
    static async getAll(restaurantId: number) {
        return request<EmployeeResponse[]>({
            uri: `employee/${restaurantId}`,
            method: 'GET'
        });
    }
    static async create(body: Employee){
        return request<EmployeeResponse>({
            uri:`employee`,
            method:"POST",
            body
        })
    }
    static async deleteById(id: number){
        return request<boolean>({
            uri:`employee/${id}`,
            method:"DELETE"
        })
    }

    static async edit(body: Partial<EmployeeResponse>) {
        return request<EmployeeResponse>({
            uri:`employee/${body.id}`,
            method:"PUT",
            body
        })
    }

}