import { useToast } from "@chakra-ui/react";
import { useLocalization } from "./useLocalization.hook";

export function useToasts() {
    const toasts = useToast();
    const {t} = useLocalization();
    
    const toastFactory = (title:string, status?: "info" | "warning" | "success" | "error" | "loading", duration?:number | null ) => {

        return toasts({title: t(title), status, variant:'top-accent', duration: duration === null ? duration: duration || 1000})

    }
    const errorToast =  (title:string) =>toastFactory(title, 'error');
    const infoToast =  (title:string) =>toastFactory(title, 'info');
    const successToast = (title:string) => toastFactory(title, 'success');
    const loadingToast = (title:string, duration:number | null) => toastFactory(title, 'loading', duration);

    return {
        toastsManager: toasts,
        loadingToast,
        errorToast,
        infoToast,
        successToast
    }
}