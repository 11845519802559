import axios, { AxiosRequestHeaders, Method } from "axios";

const axiosInstance = axios.create();

export type RequestParams = {
    uri: string;
    method: Method;
    body?: any;
    params?: any;
    removeEndSlash?: boolean;
    authentication?: boolean;
    pagination?:boolean;
    headers?:AxiosRequestHeaders;
    fullRouteProvided?:boolean;
};
export const BASE_URL = process.env.NODE_ENV === 'production' ?  `/api` : process.env.REACT_APP_API_URL;
export interface RequestResponseType<T> {
    status: number;
    data: T;
}

export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

const handleAuth = (response:any) => {
    if (response?.access_token && response?.refresh_token) {
        localStorage.setItem(ACCESS_TOKEN_KEY, response.access_token );
        localStorage.setItem(REFRESH_TOKEN_KEY, response.refresh_token);
    }
};

export async function request<T>(params: RequestParams) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
   
    const response = await axiosInstance.request({
        method: params.method,
        data: params.body,
        params: params.params,
        url: !params.fullRouteProvided ? `${BASE_URL}/${params.uri}` : params.uri,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            ...params.headers
        }
    });

    const data = response.data.data || response.data;
    handleAuth(data);

    const responseObj: RequestResponseType<T> = { status: response.status, data: data as T};
    return responseObj;
}
