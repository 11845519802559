import { Box, Heading, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import DistributionByDateGraph from "../graphs/distributionByDateGraph.component";
import useRestaurantValidation from "../../hooks/useRestaurantValidation.hook";
import useSWR from "swr";
import { RestaurantsDailyAPI } from "../../api/restaurantsDaily.api";
import { dates as DatesUtil } from "../../utils/dates.util";
import { useLocalization } from "../../hooks/useLocalization.hook";
import breakpoints from "../../styles/breakpoints";

const IncomePerDayGraph = () => {
	const { t } = useLocalization();
	const { id } = useRestaurantValidation();
	const [isMobile] = useMediaQuery(breakpoints.isMobile);
	const { data, isLoading, error } = useSWR(
		id
			? [
					"incomeDistribution",
					id,
					isMobile ? DatesUtil.lastTenDays : DatesUtil.lastTwoWeeks,
			  ]
			: null,
		([_key, id, startDate]) =>
			RestaurantsDailyAPI.getCleanProfitByDates({
				restaurantId: id,
				start: startDate,
				end: new Date(),
			}),
		{
			revalidateOnFocus: false,
		}
	);

	if (!data || isLoading) return <></>;

	const dates = [];
	const incomes = [];

	for (const item of data?.data) {
		dates.push(item.date);
		incomes.push(item.income);
	}
	return (
		<Box w="full">
			<Heading mb={2} size="md">
				{t("incomeKeys.distribution")}
			</Heading>
			<DistributionByDateGraph dates={dates} values={incomes} />
		</Box>
	);
};

export default IncomePerDayGraph;
