import React from "react";
import { Box, Heading, Link } from "@chakra-ui/react";
import { Link as ReactLink, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useLocalization } from "../../hooks/useLocalization.hook";
import { formatNumber } from "../../utils/formatNumber.util";
import { InfoBox } from "../ui-components/infoBox.component";
import { InfoBoxGrid } from "../ui-components/InfoBoxGrid.component";

type Props = {
	yesterdayData: number;
	startOfMonthData: number;
	showLink?: boolean;
	startOfTheMonthCount?: number;
	yesterdayCount?: number;
};

const DeliveryIncomes = ({
    startOfMonthData,
    yesterdayData,
    showLink,
    startOfTheMonthCount,
    yesterdayCount,
}: Props) => {
    const { t } = useLocalization();
    const location = useLocation();
    const shouldDisplayMonthlyCount =
		startOfTheMonthCount !== undefined && startOfTheMonthCount !== null;
    const shouldDisplayYesterdayCount =
		yesterdayCount !== undefined && yesterdayCount !== null;
    return (
        <Box>
            <Box
                w={"100%"}
                display={"flex"}
                flexDir={"row"}
                justifyContent={"space-between"}
            >
                <Heading mb={4} size={"md"}>
                    {t("incomePageKeys.income_delivery")}
                </Heading>
                {showLink && (
                    <Link
                        variant='secondary-title'
                        as={ReactLink}
                        to={`${location.pathname}/incomes`}
                    >
                        {`${t("routingKeys.toAllIncomes")} >`}
                    </Link>
                )}
            </Box>

            <InfoBoxGrid display='grid' w={"100%"} position={"relative"}>
                <InfoBox
                    direction='column'
                    normalText={t("dateAndTimeKeys.yesterday")}
                    boldText={formatNumber({ number: yesterdayData, allowZero: true, t })}
                />
                {shouldDisplayYesterdayCount ? (
                    <InfoBox
                        direction='column'
                        normalText={t("incomeKeys.totalDeliveriesYesterday")}
                        boldText={yesterdayCount.toString()}
                    />
                ) : (
                    ""
                )}
                <InfoBox
                    direction='column'
                    normalText={t("dateAndTimeKeys.from_month_start")}
                    boldText={formatNumber({
                        number: startOfMonthData,
                        allowZero: true,
                        t,
                    })}
                />
                {shouldDisplayMonthlyCount ? (
                    <InfoBox
                        direction='column'
                        normalText={t("incomeKeys.totalDeliveriesMonthly")}
                        boldText={startOfTheMonthCount.toString()}
                    />
                ) : (
                    ""
                )}
                <Box position={"absolute"} left={0} bottom={0}>
                    <ReactSVG src='/svg/delivery.svg' />
                </Box>
            </InfoBoxGrid>
        </Box>
    );
};

export default DeliveryIncomes;
