import { useDishes } from '../../hooks/useDishes.hook'
import { Box, Button, Heading, Input, Spinner, Stack } from '@chakra-ui/react';
import { AutoSizer, Column, Table, TableRowProps } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { useLocalization } from '../../hooks/useLocalization.hook';
import React from 'react';

type Props = {
  restaurantId: number
}

function VirtualizedDishesList({ restaurantId }: Props) {

  const { t, direction } = useLocalization();
  const { dishes, onPriceChange, onSelectChange, formActions, saveChanges, dirtyFields } = useDishes(restaurantId);

  const { formState: { errors, isSubmitting } } = formActions;
  if (!dishes) return <Spinner />

  const inputExpensesCellRenderer = (props: { rowIndex: number }) => {
    return <Input type='number' step='.01' key={props.rowIndex} border={'none'} {...formActions.register(`${props.rowIndex}.expenses`)} onChange={(e) => onPriceChange(props.rowIndex, +e.currentTarget.value, 'expenses')} inputMode='numeric' />
  }

  const inputIncomeCellRenderer = (props: { rowIndex: number }) => {

    return <Input type='number' step='.01' key={props.rowIndex} border={'none'} {...formActions.register(`${props.rowIndex}.incomes`)} onChange={(e) => onPriceChange(props.rowIndex, +e.currentTarget.value, 'incomes')} inputMode='numeric' />
  }

  const selectCellRenderer = (props: { rowIndex: number }) => {
    const shouldColor = Boolean(dirtyFields[props.rowIndex]);
    return <select dir='rtl'
      key={props.rowIndex}
      style={{ background: shouldColor ? '#eeebeb	' : 'unset' }}
      defaultValue={'literal'}
      {...formActions.register(`${props.rowIndex}.calculation_type`)}
      onChange={(e) => onSelectChange(props.rowIndex, e.currentTarget.value as 'relational' | 'literal')}>
      <option value='literal'>₪</option>
      <option value='relational'>%</option>
    </select>
  }

  const rowRenderer = (props: TableRowProps) => {
    const shouldColor = Boolean(dirtyFields[props.index]);
    return <div key={props.index} style={{ background: shouldColor ? '#eeebeb	' : 'unset', display: 'flex', direction, ...props.style }}>{props.columns.map((data) => {
      return React.createElement(data.type, data.props)
    })}</div>
  }

  return (
    <Box p={[0, 10]} display={'flex'} flexDir={'column'} w={['95vw', '85vw', '80vw', '50vw']} as='form' onSubmit={formActions.handleSubmit(saveChanges)} >
      <Heading mb={4}>{t('dishesKeys.title')}</Heading>
      <Box h={['45vh', '50vh', '55vh', '60vh']} display={'flex'} justifyContent={'flex-start'} left={'auto'}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={20}

              rowHeight={30}
              rowCount={dishes.length}
              rowGetter={({ index }) => dishes[index]}
              rowRenderer={(row: TableRowProps) => rowRenderer(row)}
            >
              <Column label={t('dishesKeys.dish_name')} dataKey="dish_name" width={100} />
              <Column width={200} label={t('dishesKeys.categories')} dataKey='categories' />
              <Column width={100} label={t('dishesKeys.expense_price')} dataKey="expenses" cellRenderer={({ dataKey, rowData, cellData, rowIndex }) => inputExpensesCellRenderer({ rowIndex })} />
              <Column width={100} label={t('dishesKeys.calculation_type')} dataKey="calculation_type" cellRenderer={({ dataKey, rowData, cellData, rowIndex }) => selectCellRenderer({ rowIndex })} />
              <Column width={100} label={t('dishesKeys.income_price')} dataKey="incomes" cellRenderer={({ dataKey, rowData, cellData, rowIndex }) => inputIncomeCellRenderer({ rowIndex })} />
            </Table >
          )}
        </AutoSizer>
      </Box>
      <Stack w='full' alignItems={'end'}><Button isLoading={isSubmitting} mt={[2, 3, 4, 5]} type='submit' w={'fit-content'} my={10}>{t('save')}</Button></Stack>


    </Box >
  )

}
export default VirtualizedDishesList