import { DishesRequest } from './request.types';
import { DishesResponse } from './response.types';
import { request } from '../utils/api.util';

export class DishesAPI {
    static async getByRestaurant(restaurantId:number) {
        return request<DishesResponse[]>({
            uri: `dishes/${restaurantId}`,
            method: 'GET'
        });
    }
    static async getCategoriesByRestaurant(restaurantId:number) {
        return request<string[][]>({
            uri: `dishes/${restaurantId}/categories`,
            method: 'GET'
        });
    }
    static async updateByRestaurant(restaurantId:number, body:DishesRequest[]) {
        return request<boolean>({
            uri: `dishes/${restaurantId}`,
            method: 'PATCH',
            body
        });
    }
}