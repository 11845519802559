import React from "react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Heading,
	Spinner,
	Stack,
} from "@chakra-ui/react";
import useRestaurant from "../../hooks/useRestaurant.hook";
import Item from "../ui-components/formItem.component";
import { RestaurantRequest } from "../../api/request.types";
import { useLocalization } from "../../hooks/useLocalization.hook";

export const RestaurantEditComponent = () => {
	const { useFormParams, restaurantLoading, updateRestaurant } =
		useRestaurant();
	const { t, direction } = useLocalization();
	const title =
		direction === "rtl"
			? `${t("tabNames.settings_tab")} ${t("dateAndTimeKeys.monthly")}`
			: `${t("dateAndTimeKeys.monthly")} ${t("tabNames.settings_tab")}`;

	const {
		formState: { errors, isSubmitting },
	} = useFormParams;
	return (
		<Box p={[0, 10]} w={["", "", "100%", "50%"]}>
			{restaurantLoading ? (
				<Spinner />
			) : (
				<>
					<Heading mb={10}>{title}</Heading>
					<form
						onSubmit={useFormParams?.handleSubmit(() => updateRestaurant?.())}
					>
						<FormControl isRequired>
							<Box display={"flex"} flexDir={"column"} gap={4} mb={5}>
								<Item<RestaurantRequest>
									name="income_goal"
									displayName={t("analyticsKeys.incomeGoal")}
									formActions={useFormParams}
								/>
								<Item<RestaurantRequest>
									isInvalid={!!errors.work_days}
									name="work_days"
									displayName={t("analyticsKeys.workDays")}
									formActions={useFormParams}
									inputMode="numeric"
									type="number"
									validation={{ required: true, min: 1, max: 31 }}
								>
									<FormHelperText>
										{t("inputHelpers.values_between")} 1 - 31
									</FormHelperText>
								</Item>
								<Item<RestaurantRequest>
									name="unexpected_costs"
									displayName={t("analyticsKeys.unexpectedCosts")}
									formActions={useFormParams}
									type="number"
									inputMode="numeric"
									validation={{ min: 0 }}
								/>
								<Item<RestaurantRequest>
									name="fixed_costs"
									displayName={t("analyticsKeys.fixedCosts")}
									formActions={useFormParams}
									inputMode="numeric"
									type="number"
									validation={{ min: 0 }}
								/>
								<Item<RestaurantRequest>
									name="flexible_costs"
									displayName={t("analyticsKeys.flexibleCosts")}
									formActions={useFormParams}
									inputMode="numeric"
									type="number"
									validation={{ min: 0 }}
								/>
							</Box>
						</FormControl>
						<Stack w="full" alignItems={"end"}>
							<Button isLoading={isSubmitting} type="submit" w={"fit-content"}>
								{t("submit")}
							</Button>
						</Stack>
					</form>
				</>
			)}
		</Box>
	);
};
