type Props ={
    number?: number | null
    maxDigits? :number 
    allowZero? :boolean
    t: (value:string) => string
}

export const formatNumber =  ({number , maxDigits, allowZero, t}: Props) => { 
    if((!allowZero && !number) ||  (allowZero && (number === undefined ||  number === null))) return t("errors.noData");
    return number!.toLocaleString(undefined, {maximumFractionDigits:maxDigits ?? 2}) + " ₪";

};