import dayjs from "dayjs";

export const dates = {
    yesterday: dayjs().add(-1, "days").toDate(),
    startOfMonth: dayjs().startOf("month").add(1, "day").toDate(),
    lastWeek: dayjs().add(-7, "days").toDate(),
    lastTenDays: dayjs().add(-10, "days").toDate(),
    lastTwoWeeks: dayjs().add(-2, "weeks").toDate(),
    lastThreeWeeks: dayjs().add(-3, "weeks").toDate(),
    lastFourWeeks: dayjs().add(-4, "weeks").toDate()
};

export const calcStartOfPickedDateMonth = (selectedDate:Date) => {
    return dayjs(selectedDate).startOf("month").add(1,'day').toDate();
};

export const getNextDay = (date:Date) => {
    return dayjs(date).add(1, "day").toDate();
};