import useAuth, { AuthState } from '../hooks/useAuth.hook';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import EmployeesList from '../components/employees-components/employeesList.component';
import { RestaurantEditComponent } from '../components/ui-components/restaurantEdit.component';
import { useLocalization } from '../hooks/useLocalization.hook';
import CreateEmployee from '../components/createEmployee.component';
import VirtualizedDishesList from '../components/dishes-components/virtualizedDishesList.component';
import { useMemo } from 'react';
import CategoriesList from '../components/dishes-components/categories.list.component';

function SettingsPage() {
    const { me, authStates } = useAuth();
    const { id } = useParams();
    const { t } = useLocalization();
    const restaurantId = parseInt(id!);
    const isAdmin = useMemo(() => {
        return authStates.includes(AuthState.ADMIN)
    }, [authStates])
    if (!me || !restaurantId) return <></>
    if (!isAdmin) return <CreateEmployee restaurantId={restaurantId} />


    return (
        <Tabs variant={'soft-rounded'} colorScheme='linkedin' isFitted >
            <TabList maxW={'95%'} overflowX={"scroll"}>
                <Tab>{t('tabNames.settings_tab')}</Tab>
                <Tab>{t('tabNames.employees_tab')}</Tab>
                <Tab>{t('add') + " " + t('employeeAttributesKeys.employees')}</Tab>
                <Tab>{t('tabNames.dishes_tab')}</Tab>
                <Tab>{t('dishesKeys.categories')}</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <RestaurantEditComponent />
                </TabPanel>
                <TabPanel>
                    <EmployeesList restaurantId={restaurantId} />
                </TabPanel>
                <TabPanel>
                    <CreateEmployee restaurantId={restaurantId} />
                </TabPanel>
                <TabPanel>
                    <VirtualizedDishesList restaurantId={restaurantId} />
                </TabPanel>z
                <TabPanel>
                    <CategoriesList restaurantId={restaurantId} />
                </TabPanel>
            </TabPanels>
        </Tabs >
    )
}

export default SettingsPage