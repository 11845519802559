import { Box, Heading, Link, Spinner } from '@chakra-ui/react';
import { InfoBoxGrid } from '../ui-components/InfoBoxGrid.component';
import { InfoBox } from '../ui-components/infoBox.component';
import { useLocalization } from '../../hooks/useLocalization.hook';
import useRestaurant from '../../hooks/useRestaurant.hook';
import { formatNumber } from '../../utils/formatNumber.util';
import { Link as ReactLink } from 'react-router-dom';


type Props = {
    restaurantId: number;
    showLink?: boolean
}

function ExpensesAnalyticalData({ restaurantId, showLink }: Props) {
    const { t } = useLocalization();
    const { restaurant, restaurantLoading } = useRestaurant(restaurantId);
    if (restaurantLoading) return <Spinner />
    if (!restaurant) return <></>

    const { work_days, fixed_costs, flexible_costs, unexpected_costs } = restaurant!;

    return (
        <Box>
            <Box w={'100%'} display={'flex'} flexDir={'row'} justifyContent={'space-between'}>
                {showLink && <Link variant="secondary-title" as={ReactLink} to={location.pathname + "/expenses"}>{t('routingKeys.toAllExpenses') + " >"}</Link>}
            </Box>
            <InfoBoxGrid display='grid' w='100%'>
                <InfoBox direction='column' normalText={t('expensesKeys.expenses_fixed')} boldText={formatNumber({ number: (+fixed_costs / work_days), t })} />
                <InfoBox direction='column' normalText={t('expensesKeys.expenses_unexpected')} boldText={formatNumber({ number: +unexpected_costs / work_days, t })} />
                <InfoBox direction='column' normalText={t('expensesKeys.expenses_flexible')} boldText={formatNumber({ number: +flexible_costs / work_days, t })} />
            </InfoBoxGrid>
        </Box>
    )
}

export default ExpensesAnalyticalData