import { Button, Box } from '@chakra-ui/react'
import { useLocalization } from '../../../hooks/useLocalization.hook'
import useAuth from '../../../hooks/useAuth.hook';

function Footer() {
    const { changeLanguage, t, direction } = useLocalization();
    const { logout, me } = useAuth()
    const changeableLangCode = direction === 'rtl' ? 'English' : 'עברית';
    return (
        <Box display={'flex'} w="100%" justifyContent={'space-around'}>
            {me && <Button color={'white'} variant={'link'} onClick={logout}>{t('pageName.logout')}</Button>}
            <Button color={'white'} variant={'link'} onClick={changeLanguage}>{changeableLangCode}</Button>
        </Box>
    )
}

export default Footer