import React from "react";
import {
    Grid,
    GridItem,
    Heading,
    Spinner,
    Stack,
    useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Discounts from "../components/expenses-page-components/discounts.component";
import DishCosts from "../components/expenses-page-components/dishCosts.component";
import EmployeesCosts from "../components/expenses-page-components/employeesCosts.component";
import ExpensesAnalyticalData from "../components/expenses-page-components/expensesAnalyticsData.component";
import Refunds from "../components/expenses-page-components/refunds.component";
import TotalExpenses from "../components/expenses-page-components/totalExpenses.component";
import useAuth from "../hooks/useAuth.hook";
import { useLocalization } from "../hooks/useLocalization.hook";
import { useRestaurantDateRange } from "../hooks/useRestaurantDateRange.hook";
import breakpoints from "../styles/breakpoints";
import { calcStartOfPickedDateMonth } from "../utils/dates.util";
import { useGlobalDateContext } from "../contexts/DateContext";

const TODAY_DATE = new Date();

function ExpensesPage() {
	const { me } = useAuth();
	const { id } = useParams();
	const { t } = useLocalization();
	const navigate = useNavigate();
    const { selectedDate } = useGlobalDateContext();
    if (!me) { navigate("/login"); }
    if (!id) { navigate("/restaurants"); }

    const [isMobile] = useMediaQuery(breakpoints.isMobile);
    const restaurantId = parseInt(id!);
    const {
        restaurantDailyReportsData: yesterdayData,
        restaurantDailyReportsDataLoading: isYesterdayDataLoading,
    } = useRestaurantDateRange(restaurantId, {
        startDate: selectedDate,
        endDate: selectedDate,
    });
    const {
        restaurantDailyReportsData: startOfMonthData,
        restaurantDailyReportsDataLoading: isStartOfMonthDataLoading,
    } = useRestaurantDateRange(restaurantId, {
        startDate: calcStartOfPickedDateMonth(selectedDate),
        endDate: selectedDate,
    });

    if (isYesterdayDataLoading || isStartOfMonthDataLoading) { return <Spinner />; }
    if (!yesterdayData || !startOfMonthData) { return <h1>Error</h1>; }
    if (isMobile) {
        return (
            <Stack spacing={"2vh"} justifyContent={"center"} w={"95vw"}>
                <Heading>{t("pageName.expenses")}</Heading>
                <ExpensesAnalyticalData restaurantId={restaurantId} />
                <Discounts
                    yesterdayData={yesterdayData?.totalDiscount}
                    startOfMonthData={startOfMonthData?.totalDiscount}
                />
                <Refunds
                    yesterdayData={yesterdayData?.totalRefund}
                    startOfMonthData={startOfMonthData?.totalRefund}
                />
                <DishCosts
                    yesterdayData={yesterdayData?.totalDishesExpenses}
                    startOfMonthData={startOfMonthData?.totalDishesExpenses}
                />
                <EmployeesCosts restaurantId={restaurantId} />
                <TotalExpenses
                    yesterdayExpenses={0}
                    startOfMonthExpenses={0}
                />
            </Stack>
        );
    }
    return (
        <Grid templateColumns={"repeat(8,1fr)"} columnGap={"20px"} rowGap={"3vh"}>
            <Heading>{t("pageName.expenses")}</Heading>
            <GridItem colSpan={8}>
                <ExpensesAnalyticalData restaurantId={restaurantId} />
            </GridItem>
            <GridItem colSpan={4}>
                <Discounts
                    yesterdayData={yesterdayData?.totalDiscount}
                    startOfMonthData={startOfMonthData?.totalDiscount}
                />
            </GridItem>
            <GridItem colSpan={4}>
                <Refunds
                    yesterdayData={yesterdayData?.totalRefund}
                    startOfMonthData={startOfMonthData?.totalRefund}
                />
            </GridItem>
            <GridItem colSpan={4}>
                <DishCosts
                    yesterdayData={yesterdayData?.totalDishesExpenses}
                    startOfMonthData={startOfMonthData?.totalDishesExpenses}
                />
            </GridItem>
            <GridItem colSpan={4}>
                <EmployeesCosts restaurantId={restaurantId} />
            </GridItem>
            <GridItem colSpan={8}>
                <TotalExpenses
                    yesterdayExpenses={0}
                    startOfMonthExpenses={0}
                />
            </GridItem>
        </Grid>
    );
}

export default ExpensesPage;
