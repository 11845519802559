import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from './useAuth.hook';

export default function useRestaurantValidation(restaurantId?:number) {
    const {me} = useAuth();
    const navigate = useNavigate();
    const {id:paramsId} = useParams();
    const id = restaurantId || paramsId;

    useEffect(() => {
        if (me && id && !me?.restaurants.includes(Number(id))) {
            navigate('/404');
        }
    }, [ id, me, navigate ]);

    return {
        id: Number(id)
    };

}