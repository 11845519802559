import React from "react";

import { Box, Heading, Spinner, Stack, useMediaQuery } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import RestaurantAnalyticsData from "../components/restaurantAnalyticsData.component";
import EndOfShiftForm from "../components/shift-report-components/endOfShiftForm.component";
import NoShiftData from "../components/shift-report-components/noShiftData.component";
import ShiftDescription from "../components/shift-report-components/shiftDescription.component";
import ShiftTips from "../components/shift-report-components/shiftTips.component";
import useAuth from "../hooks/useAuth.hook";
import { useLocalization } from "../hooks/useLocalization.hook";
import useRestaurantDailyData from "../hooks/useRestaurantDailyData.hook";
import breakpoints from "../styles/breakpoints";

const ShiftReport = () => {
    const { me } = useAuth();
    const { t } = useLocalization();
    const { id } = useParams();
    const restaurantId = parseInt(id!);
    const [isMobile] = useMediaQuery(breakpoints.isMobile);
    const navigate = useNavigate();
    const {
        selectedDate,
        restaurantDailyData,
        isRestaurantDailyDataLoading,
        formActions,
        calculateTip,
        editOrCreateDescription,
    } = useRestaurantDailyData(restaurantId);

    if (!restaurantId || !me) { navigate("/login"); }
    if (isRestaurantDailyDataLoading) { return <Spinner />; }

    return (
        <Box w={isMobile ? "95vw" : ""}>
            <Heading>{t("pageName.shiftReport")}</Heading>
            {me ? (
                <Stack spacing={5} my={5}>
                    {restaurantDailyData ? (
                        <>
                            <RestaurantAnalyticsData
                                restaurantDailyData={restaurantDailyData.analytics}
                            />
                            <ShiftTips
                                formActions={formActions}
                                calculateTip={calculateTip}
                                totalTip={restaurantDailyData.totalTip}
                                tipCredit={restaurantDailyData.analytics?.tipTotal}
                            />
                            <EndOfShiftForm
                                restaurantId={restaurantId}
                                restaurantDailyId={restaurantDailyData.id}
                            />
                            <ShiftDescription
                                editOrCreateDescription={editOrCreateDescription}
                                currentDescription={restaurantDailyData.description}
                            />
                        </>
                    ) : (
                        <NoShiftData
                            selectedCurrentDate={selectedDate}
                            restaurantId={restaurantId}
                        />
                    )}
                </Stack>
            ) : null}
        </Box>
    );
};

export default ShiftReport;
