import React from 'react';
import { Global } from '@emotion/react';

const Fonts = () => (
    <Global
        styles={`
      @font-face {
        font-family: 'assistant';
        font-weight: 200;
        src:  url('/fonts/assistant/Assistant-ExtraLight.woff2') format('woff2'),
              url('/fonts/assistant/Assistant-ExtraLight.woff') format('woff'),
              url('/fonts/assistant/Assistant-ExtraLight.eot') format('eot'),
              url('/fonts/assistant/Assistant-ExtraLight.ttf') format('ttf')
      }

      @font-face {
        font-family: 'assistant';
        font-weight: 300;
        src:  url('/fonts/assistant/Assistant-Light.woff2') format('woff2'),
              url('/fonts/assistant/Assistant-Light.woff') format('woff'),
              url('/fonts/assistant/Assistant-Light.eot') format('eot'),
              url('/fonts/assistant/Assistant-Light.ttf') format('ttf')
      }

      @font-face {
        font-family: 'assistant';
        font-weight: 400;
        src:  url('/fonts/assistant/Assistant-Regular.woff2') format('woff2'),
              url('/fonts/assistant/Assistant-Regular.woff') format('woff'),
              url('/fonts/assistant/Assistant-Regular.eot') format('eot'),
              url('/fonts/assistant/Assistant-Regular.ttf') format('ttf')
      }

      @font-face {
        font-family: 'assistant';
        font-weight: 500;
        src:  url('/fonts/assistant/Assistant-SemiBold.woff2') format('woff2'),
              url('/fonts/assistant/Assistant-SemiBold.woff') format('woff'),
              url('/fonts/assistant/Assistant-SemiBold.eot') format('eot'),
              url('/fonts/assistant/Assistant-SemiBold.ttf') format('ttf')
      }

      @font-face {
        font-family: 'assistant';
        font-weight: 700;
        src:  url('/fonts/assistant/Assistant-Bold.woff2') format('woff2'),
              url('/fonts/assistant/Assistant-Bold.woff') format('woff'),
              url('/fonts/assistant/Assistant-Bold.eot') format('eot'),
              url('/fonts/assistant/Assistant-Bold.ttf') format('ttf')
      }

      @font-face {
        font-family: 'assistant';
        font-weight: 900;
        src:  url('/fonts/assistant/Assistant-ExtraBold.woff2') format('woff2'),
              url('/fonts/assistant/Assistant-ExtraBold.woff2') format('woff'),
              url('/fonts/assistant/Assistant-ExtraBold.eot') format('eot'),
              url('/fonts/assistant/Assistant-ExtraBold.ttf') format('ttf')
      }
      `}
    />
);

export default Fonts;